import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react';
import {
  Ancestor,
  createEditor,
  Descendant,
  Editor,
  Node,
  NodeEntry,
  Element as SlateElement,
} from 'slate';
import { withHistory } from 'slate-history';
import _ from 'lodash';
import styled from 'styled-components';
import ToolBar from './ToolBar';
import { colorMatch } from './utils';

const RichTextTable = styled.table`
  border-radius: 2px;
  border: 1px solid ${({ theme }): string => theme.colors.black10Alpha};
  border-collapse: collapse;
`;

const RichTextCell = styled.td`
  border: 1px solid ${({ theme }): string => theme.colors.black10Alpha};
  width: 135px;
  height: 36px;
  padding: 10px;
  font-size: 14px;
  font-weight: 300;
`;

const Element = ({
  attributes,
  children,
  element,
}: RenderElementProps): JSX.Element => {
  switch (element.type) {
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'table':
      return (
        <RichTextTable {...attributes}>
          <tbody>{children}</tbody>
        </RichTextTable>
      );
    case 'table-row':
      return <tr {...attributes}>{children}</tr>;
    case 'table-cell':
      return <RichTextCell {...attributes}>{children}</RichTextCell>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

type CustomRenderLeafAttributes = {
  'data-slate-leaf': true;
  style: Record<string, string | number[]>;
};
interface CustomRenderLeafProps extends RenderLeafProps {
  attributes: CustomRenderLeafAttributes;
}

const Leaf = ({
  attributes,
  children,
  leaf,
}: CustomRenderLeafProps): JSX.Element => {
  const newAttributes = attributes;
  let newChildren = children;

  Object.keys(leaf).forEach((item) => {
    const itemMatches = item.match(colorMatch);
    if (item.includes('textColour')) {
      const textColour =
        itemMatches && itemMatches.length > 0 ? itemMatches[0] : '';
      newAttributes.style = { ...attributes.style, color: textColour };
    }
    if (item.includes('highlightColour')) {
      const highlightColour =
        itemMatches && itemMatches.length > 0 ? itemMatches[0] : '';
      newAttributes.style = {
        ...attributes.style,
        backgroundColor: highlightColour,
      };
    }
  });

  if (leaf.bold) {
    newChildren = <strong>{newChildren}</strong>;
  }

  if (leaf.italic) {
    newChildren = <em>{newChildren}</em>;
  }

  return <span {...newAttributes}>{newChildren}</span>;
};

const Wrapper = styled.div`
  background-color: white;
  resize: both;
  overflow-y: scroll;
  padding: 10px;
  border-radius: 2px;
  max-width: 100%;
  min-height: 150px;
  border: solid 1px rgba(211, 211, 211, 0.6);
  p {
    line-height: 20px;
  }
`;

export type RichTextEditorProps = {
  initialValue?: SetStateAction<Node[]>;
  onChange?: (newValue: Node[]) => void;
  onBlur?: (name: string, value: Node[]) => void;
  name: string;
  placeholder?: string;
};

const defaultValue = [{ type: 'paragraph', children: [{ text: '' }] }];

const RichTextEditor = ({
  initialValue = [],
  onChange,
  onBlur,
  name,
  placeholder = '...',
}: RichTextEditorProps): JSX.Element => {
  const [value, setValue] = useState<Descendant[] | Node[]>([]);
  const [resetMenus] = useState(false);
  const editor = useMemo(
    () => withHistory(withReact(createEditor() as ReactEditor)),
    []
  );
  const allowSave = useRef<boolean>(false);
  const handleOnBlur = (overrideFocus: boolean): void => {
    if (onBlur && (overrideFocus || allowSave.current)) {
      onBlur(name, value);
    }
  };
  useEffect(() => {
    if (_.isEqual(value, defaultValue) || value.length === 0) {
      try {
        setValue((initialValue.length > 0 && initialValue) || defaultValue);
      } catch (e) {
        console.error(e);
      }
    }
  }, [initialValue, editor, value]);

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (
    event
  ): void => {
    if (event.key === 'Backspace') {
      const point = editor?.selection?.focus || {
        path: [0],
        offset: 0,
      };
      const path = editor?.selection?.focus?.path || [0];
      const [emptyTableCell]: Generator<
        NodeEntry<Node>,
        void,
        undefined
      > = Editor.nodes(editor, {
        match: (n: Node) =>
          (n as SlateElement).type === 'table-cell' &&
          Editor.isStart(editor, point, path),
      });
      // we want to prevent cell deletion from tables
      if (emptyTableCell && editor.selection) {
        const parent = Editor.parent(editor, editor.selection.focus.path, {
          edge: 'start',
        });
        const grandParent: Ancestor = Editor.parent(editor, parent[1])[0];
        if (
          (grandParent as SlateElement).type === 'table-cell' &&
          grandParent.children.length < 2
        ) {
          event.preventDefault();
        }
      }
    }
  };

  const setNewEditorValue = (newContent: Node[]): void => {
    let currentPath: Editor | Descendant | null = editor;
    if (editor.selection !== null) {
      // This is required to prevent bad paths from being sent into the editor (causes crash)
      editor.selection.anchor.path.forEach((item) => {
        if (currentPath && currentPath.children && currentPath.children[item]) {
          currentPath = currentPath.children[item];
        } else {
          currentPath = null;
        }
      });
      if (currentPath !== null) {
        try {
          const newValue = _.cloneDeep(newContent);
          setValue(_.cloneDeep(newContent));
          if (onChange) onChange(newValue);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  return (
    <Wrapper data-testid="RichTextEditor">
      <Slate
        editor={editor}
        value={value as Descendant[]}
        onChange={setNewEditorValue}
      >
        <ToolBar
          name={name}
          onBlur={handleOnBlur}
          editor={editor}
          resetMenus={resetMenus}
          value={value}
        />
        <Editable
          onKeyDown={handleKeyDown}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
          onBlur={() => {
            handleOnBlur(true);
          }}
        />
      </Slate>
    </Wrapper>
  );
};

export default RichTextEditor;
