import styled from 'styled-components';

const CustomisableButton = styled.button`
  background: var(--brand-blue);
  border-radius: 15px;
  border: none;
  box-shadow: 0 0 0 1px var(--brand-blue);
  color: var(--white);
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 15px;
  transition: background 150ms ease-in-out, border 150ms ease-in-out,
    transform 100ms ease;
  &:hover {
    background: var(--cobalt);
    box-shadow: 0 0 0 1px var(--cobalt);
  }
  &:active {
    background: var(--brand-blue-80);
    box-shadow: 0 0 0 1px var(--brand-blue-80);
  }
  &:focus {
    box-shadow: 0 0 0 1px var(--jordy);
    outline: none;
  }
  &:disabled {
    background-color: var(--brand-blue-40);
    box-shadow: 0 0 0 1px var(--brand-blue-40);
    cursor: not-allowed;
  }
`;

export default CustomisableButton;
