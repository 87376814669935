import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import activeIcon from '../../assets/RichTeIcons/icon-table-active.svg';
import inactiveIcon from '../../assets/RichTeIcons/icon-table.svg';
import TablePopupMenu from './TableMenu/TablePopupMenu';
import { ToolButton, ToolButtonWrapper } from './ToolBarStyles';
import ToolTip from './ToolTip';
import { isBlockActive } from './utils';

type TableButtonProps = {
  format: string;
  menuToggle: Dispatch<SetStateAction<boolean>>;
  showMenu: boolean;
  onBlur: (overrideFocus: boolean) => void;
  toggleTextMenu: (toggle: boolean) => void;
  toggleHighlightMenu: (toggle: boolean) => void;
  otherMenuStates: boolean[];
};

export default ({
  format,
  menuToggle,
  showMenu,
  onBlur,
  toggleTextMenu,
  toggleHighlightMenu,
  otherMenuStates,
}: TableButtonProps): JSX.Element => {
  const editor: Editor = useSlate();
  const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);

  const handleMenuToggle = useCallback(() => {
    if (otherMenuStates.includes(true)) {
      toggleTextMenu(false);
      toggleHighlightMenu(false);
    }
    menuToggle((menuState) => !menuState);
  }, [otherMenuStates, menuToggle, toggleHighlightMenu, toggleTextMenu]);

  const tableInEditMode = isBlockActive(editor, format);

  const icon = tableInEditMode ? activeIcon : inactiveIcon;

  return (
    <>
      <ToolButtonWrapper>
        <ToolTip text="table" isVisible={tooltipIsVisible && !showMenu}>
          <ToolButton
            type="button"
            onMouseDown={handleMenuToggle}
            onMouseEnter={() => {
              setTooltipIsVisible(true);
            }}
            onMouseLeave={() => {
              setTooltipIsVisible(false);
            }}
          >
            <img src={icon} alt="block button" />
          </ToolButton>
        </ToolTip>
      </ToolButtonWrapper>
      {showMenu && (
        <TablePopupMenu
          editor={editor}
          menuToggle={menuToggle}
          tableInEditMode={tableInEditMode}
          format={format}
          onBlur={onBlur}
        />
      )}
    </>
  );
};
