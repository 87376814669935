import React from 'react';
import styled from 'styled-components';
import TextColourLetterBlack from '../../assets/RichTeIcons/icon-text-colour-black.svg';
import TextColourLetterBlue from '../../assets/RichTeIcons/icon-text-colour-blue.svg';
import TextColourLetterOrange from '../../assets/RichTeIcons/icon-text-colour-orange.svg';
import HighlightColourMagenta from '../../assets/RichTeIcons/icon-highlight-magenta.svg';
import HighlightColourYellow from '../../assets/RichTeIcons/icon-highlight-yellow.svg';
import HighlightColourGreen from '../../assets/RichTeIcons/icon-highlight-green.svg';

export const ToolButtonWrapper = styled.div`
  position: relative;
`;

export const ToolButton = styled.button<{ disabled?: boolean }>`
  margin: 2px;
  border: none;
  background: transparent;

  &:hover {
    cursor: ${({ disabled }) => (!disabled ? 'pointer;' : 'default;')};
    img {
      ${({ disabled }) =>
        !disabled ? 'background-color: rgba(24, 144, 255, 0.06);' : ''};
    }
  }
  &:focus {
    outline: none;
  }
`;

export const colorIcons: Record<string, JSX.Element> = {
  '#000000': <img src={TextColourLetterBlack} alt="mark button" />,
  '#1600ff': <img src={TextColourLetterBlue} alt="mark button" />,
  '#ffb84c': <img src={TextColourLetterOrange} alt="mark button" />,
  '#fe02fe': <img src={HighlightColourMagenta} alt="mark button" />,
  '#fef202': <img src={HighlightColourYellow} alt="mark button" />,
  '#00ff00': <img src={HighlightColourGreen} alt="mark button" />,
};

export const toolBarColours: Record<string, Record<string, string>> = {
  text: {
    black: '#000000',
    blue: '#1600ff',
    yellow: '#ffb84c',
  },
  highlight: {
    pink: '#fe02fe',
    flYellow: '#fef202',
    green: '#00ff00',
  },
};

export const HistoryButtonIcon = styled.img<{ disabled: boolean }>`
  opacity: ${({ disabled }) => (disabled ? '20%' : '100%')};
`;
