import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Button } from '../Button/Button';
import dropdownIcon from '../../assets/icon-dropdown-arrowhead.svg';
import DropdownMenu, { MenuItemDefinition } from './DropdownMenu';
import {
  ButtonDropdownIcon,
  ButtonMenuWrapper,
  ComponentWrapper,
} from './DropdownStyles';
import subMenuVariants from './subMenuVariants';

export type DropdownMenuWithButtonProps = {
  initialActiveItem?: MenuItemDefinition | undefined;
  openButtonText?: string;
  closeButtonText?: string;
  isRightAligned?: boolean;
  menuItems: MenuItemDefinition[];
  onSelect: (menuItem: MenuItemDefinition) => void;
};

const DropdownMenuWithButton: React.FC<DropdownMenuWithButtonProps> = ({
  initialActiveItem,
  openButtonText = 'Open',
  closeButtonText = 'Close',
  isRightAligned = false,
  menuItems,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOnButton, setIsOnButton] = useState(false);
  return (
    <ButtonMenuWrapper
      data-testid="Button__Wrapper"
      isRightAligned={isRightAligned}
    >
      <div className="button-wrapper">
        <Button
          onClick={(): void => {
            setIsOpen(!isOpen);
          }}
          onMouseEnter={() => {
            setIsOnButton(true);
          }}
          onMouseLeave={() => {
            setIsOnButton(false);
          }}
        >
          <div className="button-content-wrapper">
            <span>{isOpen ? closeButtonText : openButtonText}</span>
            <ButtonDropdownIcon src={dropdownIcon} alt="" isOpen={isOpen} />
          </div>
        </Button>
      </div>

      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <OutsideClickHandler
            onOutsideClick={() => {
              if (isOpen && !isOnButton) {
                setIsOpen(false);
              }
            }}
          >
            <ComponentWrapper
              data-testid="DropdownMenuWithButton__Wrapper"
              variants={subMenuVariants}
              initial="initial"
              animate="enter"
              exit="exit"
            >
              <DropdownMenu
                menuItems={menuItems}
                initialActiveItem={initialActiveItem}
                isRightAligned={isRightAligned}
                onSelect={(event) => {
                  setIsOpen(false);
                  onSelect(event);
                }}
              />
            </ComponentWrapper>
          </OutsideClickHandler>
        )}
      </AnimatePresence>
    </ButtonMenuWrapper>
  );
};

export default DropdownMenuWithButton;
