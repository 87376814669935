import React from 'react';
import styled from 'styled-components';

export interface ButtonProps {
  primary?: boolean;
  onClick: (e: React.MouseEvent) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  square?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}

const ButtonWrapper = styled.button<{
  primary: boolean;
  square?: boolean;
  disabled?: boolean;
}>`
  border-radius: ${({ square }): string => (square ? '' : '15px')};
  padding: 6px 15px;
  opacity: ${({ disabled }): string => (disabled ? '0.5;' : '1;')};
  background: ${({ primary, theme }): string =>
    primary ? theme.colors.brandBlue : theme.colors.white};
  border: ${({ primary, theme }): string =>
    primary
      ? `1px solid ${theme.colors.brandBlue}`
      : `1px solid ${theme.colors.black}`};
  color: ${({ primary, theme }): string =>
    primary ? theme.colors.white : theme.colors.black};
  font-size: 14px;
  font-weight: 400;
  cursor: ${({ disabled }): string => (disabled ? 'default;' : 'pointer;')};
  transition: background 150ms ease-in-out, border 150ms ease-in-out,
    transform 100ms ease;

  &:hover {
    background: ${({ disabled, primary, theme }): string =>
      primary && !disabled ? theme.colors.cobalt : ''};
    background: ${({ disabled, primary, theme }): string =>
      !primary && !disabled ? theme.colors.white : ''};
    border: ${({ disabled, primary, theme }): string =>
      !primary && !disabled ? `1px solid ${theme.colors.black20Alpha}` : ''};
  }
  &:active {
    background: ${({ primary }): string =>
      primary ? 'rgba(24, 144, 255, 0.8)' : '#fafafa'};
    border: ${({ primary, theme }): string =>
      primary
        ? `1px solid ${theme.colors.cobalt}`
        : '1px solid rgba(36, 45, 65, 0.2)'};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(126, 187, 239);
  }
`;

export const Button: React.FC<ButtonProps> = ({
  primary = true,
  onClick,
  onMouseEnter,
  onMouseLeave,
  children,
  square,
  disabled = false,
}) => {
  return (
    <ButtonWrapper
      primary={primary}
      square={square}
      type="button"
      onClick={onClick}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </ButtonWrapper>
  );
};
