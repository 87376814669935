import { Editor, Transforms, Node, Element } from 'slate';
import tableFactory from './TableMenu/tableFactory';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export const colorMatch = new RegExp(/#\w+/);

export const colors: Record<string, Record<string, string>> = {
  text: {
    black: '#000000',
    blue: '#1600ff',
    yellow: '#ffb84c',
  },
  highlight: {
    pink: '#fe02fe',
    flYellow: '#fef202',
    green: '#00ff00',
  },
};

export const isBlockActive = (editor: Editor, format: string): boolean => {
  try {
    const [match] = Editor.nodes(editor, {
      match: (n: Node) => (n as Element).type === format,
    });

    return !!match;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const isMarkActive = (editor: Editor, format: string): boolean => {
  try {
    const marks: Record<string, boolean> = Editor.marks(editor) as Record<
      string,
      boolean
    >;
    return marks ? marks[format] === true : false;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const toggleBlock = (editor: Editor, format: string): void => {
  try {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n: Node) => LIST_TYPES.includes((n as Element).type as string),
      split: true,
    });

    let type: string;
    if (isActive) {
      type = 'paragraph';
    } else if (isList) {
      type = 'list-item';
    } else {
      type = format;
    }

    Transforms.setNodes(editor, {
      type,
    });

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  } catch (e) {
    console.error(e);
  }
};

export const toggleMark = (editor: Editor, format: 'bold' | 'italic'): void => {
  try {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  } catch (e) {
    console.error(e);
  }
};

export const colorIsActive = (
  editor: Editor,
  currentColour: string
): boolean => {
  return isMarkActive(editor, currentColour);
};

export const toggleColour = (
  editor: Editor,
  format: string,
  { type }: { type: string }
): void => {
  try {
    let colorSubset: string[] = [];
    let colorType: string;

    if (type === 'text') {
      colorSubset = Object.values(colors.text);
      colorType = 'textColour';
    }

    if (type === 'highlight') {
      colorSubset = Object.values(colors.highlight);
      colorType = 'highlightColour';
    }

    colorSubset.forEach((color) => {
      const currentColour = `${colorType}${color}`;
      const activeColour = colorIsActive(editor, currentColour);

      if (format.includes(currentColour) && !activeColour) {
        Editor.addMark(editor, format, true);
      } else if (activeColour) {
        Editor.removeMark(editor, currentColour);
      }
    });
  } catch (e) {
    console.error(e);
  }
};

export const getTableDimension = (
  editor: Editor,
  type: string
): Record<string, number> => {
  const path = editor.selection
    ? editor.selection.anchor.path.slice(0, 1)[0]
    : 0;
  const { children } = editor;
  const selectedTable: Record<string, number> = (children[
    path
  ] as unknown) as Record<string, number>;

  if (type === 'both') {
    return { rows: selectedTable.rows, cols: selectedTable.cols };
  }

  return selectedTable;
};

type CreateAndEditTableProps = {
  editor: Editor;
  format: string;
  numOfColumns: number;
  numOfRows: number;
  deleteTable: boolean;
  onBlur: (overrideFocus: boolean) => void;
};

export const createAndEditTable = ({
  editor,
  format,
  numOfColumns,
  numOfRows,
  deleteTable = false,
  onBlur,
}: CreateAndEditTableProps): void => {
  const tableIsSelected = isBlockActive(editor, format);
  const table = tableFactory({ editor, numOfColumns, numOfRows });
  if (deleteTable) {
    try {
      Transforms.removeNodes(editor, {
        match: (n: Node) => (n as Element).type === 'table',
      });
    } catch (e) {
      console.error(e);
    }
    onBlur(true);
  }

  if (!tableIsSelected) {
    // if a table isn't currently selected create a new one
    table.makeNewTable();
  }

  if (tableIsSelected) {
    try {
      // edit currently selected table
      table.setPath();
      table.currentDimensions = getTableDimension(editor, 'both');

      if (table.rowsShouldBeIncreased()) {
        table.setStartIndex();
        table.addRows();
      } else if (table.rowsShouldBeDecreased()) {
        table.removeRows();
      }

      if (table.colsShouldBeIncreased()) {
        table.addColumns();
      } else if (table.colsShouldBeDecreased()) {
        table.removeColumns();
      }
      // once editing is complete, update the tables cols and rows helper properties
      table.updateTableSizeProperties();
      onBlur(true);
    } catch (e) {
      console.error(e);
    }
  }
};

export const ensureSelection = (editor: Editor): void => {
  if (!editor.selection) {
    Transforms.select(editor, {
      anchor: { path: [0, 0], offset: 0 },
      focus: { path: [0, 0], offset: 0 },
    });
  }
};
