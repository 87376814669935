import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { Editor } from 'slate';
import styled from 'styled-components';
import { Button } from '../../Button/Button';
import { createAndEditTable } from '../utils';

type TablePopupButtonsProps = {
  numOfColumns: number;
  numOfRows: number;
  buttonLabels: Record<string, ReactNode>;
  type: string;
  menuToggle: Dispatch<SetStateAction<boolean>>;
  deleteTable: boolean;
  editor: Editor;
  format: string;
  onBlur: (overrideFocus: boolean) => void;
};

const Wrapper = styled.div<{ type: string }>`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 20px;
`;

const SecondaryButton = styled.button`
  height: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 0px;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.brandBlue};
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`;

export default ({
  numOfColumns,
  numOfRows,
  buttonLabels: { primary, secondary },
  type,
  menuToggle,
  deleteTable = false,
  editor,
  format,
  onBlur,
}: TablePopupButtonsProps): JSX.Element => (
  <Wrapper type={type}>
    <SecondaryButton
      onClick={(): void => {
        menuToggle(false);
        if (type === 'editButtons')
          createAndEditTable({
            editor,
            format,
            numOfColumns,
            numOfRows,
            deleteTable,
            onBlur,
          });
      }}
      type="button"
    >
      {secondary}
    </SecondaryButton>
    <Button
      onClick={(): void => {
        createAndEditTable({
          editor,
          format,
          numOfColumns,
          numOfRows,
          onBlur,
          deleteTable: false,
        });
        menuToggle(false);
      }}
      disabled={!numOfColumns || !numOfRows}
    >
      {primary}
    </Button>
  </Wrapper>
);
