import React from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import NumberList from '../../assets/RichTeIcons/icon-numbered-list.svg';
import NumberListActive from '../../assets/RichTeIcons/icon-numbered-list-active.svg';
import BulletList from '../../assets/RichTeIcons/icon-bulleted-list.svg';
import BulletListActive from '../../assets/RichTeIcons/icon-bulleted-list-active.svg';
import BlockButton from './BlockButton';
import { ensureSelection, isBlockActive, toggleBlock } from './utils';

export default (): JSX.Element => {
  const editor: Editor = useSlate();

  return (
    <>
      <BlockButton
        tooltip="Numbered List"
        icon={NumberList}
        activeIcon={NumberListActive}
        data-testid="NumberListButton"
        isActive={isBlockActive(editor, 'numbered-list')}
        onToggle={() => {
          ensureSelection(editor);
          toggleBlock(editor, 'numbered-list');
        }}
      />
      <BlockButton
        tooltip="Bulleted List"
        icon={BulletList}
        activeIcon={BulletListActive}
        data-testid="BulletListButton"
        isActive={isBlockActive(editor, 'bulleted-list')}
        onToggle={() => {
          ensureSelection(editor);
          toggleBlock(editor, 'bulleted-list');
        }}
      />
    </>
  );
};
