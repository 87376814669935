import styled from 'styled-components';

export const ComponentWrapper = styled.div``;

export const MoreOptionsButton = styled.button<{ isMenuOpen: boolean }>`
  border: none;
  height: 24px;
  width: 24px;
  border-radius: 50px;
  background-color: ${({ isMenuOpen, theme }) =>
    isMenuOpen ? theme.colors.brandBlue15Alpha : 'transparent;'};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.brandBlue05Alpha};
  }
  &:focus {
    outline: none;
    border: solid 1px ${({ theme }) => theme.colors.brandBlue};
  }
`;

export const MoreOptionsIcon = styled.img`
  height: 12px;
  padding-top: 3px;
`;

export const Menu = styled.div<{
  top?: number;
  left?: number;
  right?: number;
  isMoreOnTheRight?: boolean;
  tableVariant: boolean;
}>`
  position: absolute;
  z-index: 999;
  display: grid;
  margin-top: 4px;
  ${({ top, isMoreOnTheRight, left, right }) => {
    if (isMoreOnTheRight) {
      return `right: calc(100vw - ${right}px); top: ${top}px;`;
    }
    return `left: ${left}px; top: ${top}px;`;
  }};
  padding-top: ${({ tableVariant }) => (tableVariant ? '0px;' : '4px;')};
  padding-bottom: ${({ tableVariant }) => (tableVariant ? '0px;' : '4px;')};
  padding-right: ${({ tableVariant }) => (tableVariant ? '0px;' : '10px;')};
  padding-left: ${({ tableVariant }) => (tableVariant ? '0px;' : '10px;')};
  background: white;
  box-shadow: 0 0 10px 0 ${({ theme }) => theme.colors.black10Alpha};
`;

export const MenuItemButton = styled.button<{
  isActive: boolean;
  destructive: boolean;
  tableVariant: boolean;
}>`
  background-color: white;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  height: 20px;
  margin-top: ${({ tableVariant }) => (tableVariant ? '0px;' : '10px;')};
  margin-bottom: ${({ tableVariant }) => (tableVariant ? '0px;' : '10px;')};
  text-align: left;
  padding-left: ${({ tableVariant }) => (!tableVariant ? '0px;' : '10px;')};
  padding-right: 10px;
  padding-top: ${({ tableVariant }) => (!tableVariant ? '5px;' : '10px;')};
  padding-bottom: ${({ tableVariant }) => (!tableVariant ? '19px;' : '28px;')};
  ${({ destructive, isActive, theme }): string => {
    if (!destructive) {
      return `color: ${
        isActive ? theme.colors.brandBlue : theme.colors.black80Alpha
      }; border: ${
        isActive
          ? `solid 1px ${theme.colors.brandBlue}`
          : 'solid 1px transparent'
      };`;
    }
    if (destructive) {
      return `color: ${theme.colors.red}; border: ${
        isActive ? `solid 1px ${theme.colors.red}` : 'solid 1px transparent'
      };`;
    }
    return '';
  }};
  &:hover {
    cursor: pointer;
    border: solid 1px transparent;
    ${({ destructive, tableVariant, theme }): string => {
      if (tableVariant) {
        return `background-color: ${
          destructive ? theme.colors.red5Alpha : theme.colors.brandBlue05Alpha
        };`;
      }
      return '';
    }};
  }
  &:focus {
    outline: none;
    ${({ destructive, tableVariant, theme }): string => {
      if (tableVariant) {
        return `background-color: ${
          destructive ? theme.colors.red10Alpha : theme.colors.brandBlue10Alpha
        };`;
      }
      return `color: ${theme.colors.brandBlue80Alpha};`;
    }};
  }
`;

export const MenuItemLabel = styled.span`
  white-space: nowrap;
`;

export const MenuItemIcon = styled.img`
  vertical-align: bottom;
  height: 16px;
  margin-left: 10px;
  margin-right: 5px;
`;
