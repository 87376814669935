import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import iconExpandAccordion from '../../assets/icon-expand-accordion.svg';
import iconCollapseAccordion from '../../assets/icon-collapse-accordion.svg';

const HeaderBox = styled.div<{ isExpanded: boolean }>`
  display: flex;
  width: 100%;
  border: solid 1px ${({ theme }): string => theme.colors.fog};
  border-radius: 4px;
  ${({ isExpanded }): string =>
    isExpanded
      ? 'border-bottom-left-radius: 0px; border-bottom-right-radius:0px'
      : ''};
  min-height: 40px;
  cursor: pointer;
  overflow-x: hidden;
  overflow-y: visible;
  &:focus {
    outline: none;
    border: solid 1px ${({ theme }): string => theme.colors.brandBlue};
  }
  &:active {
    border: solid 1px ${({ theme }): string => theme.colors.fog};
  }
`;

const HeaderContent = styled.div`
  width: 100%;
  display: flex;
`;

const HeaderContentWrapper = styled.div`
  display: flex;
  width: calc(100%);
`;

const ExpansionWrapper = styled.div`
  position: relative;
  height: inherit;
`;

const ExpansionIcon = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(100% - 20px);
  margin: auto;
`;

const ContentAnimationWrapper = styled(AnimatePresence)`
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ContentWrapper = styled(motion.section)`
  height: 400px;
  width: 100%;
  border: solid 1px ${({ theme }): string => theme.colors.fog};
  border-top: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Content = styled(motion.div)`
  ::-webkit-scrollbar {
    display: none;
  }
`;

export type AccordionProps = {
  headerContent: string | ReactNode;
  children?: ReactNode | ReactNode[];
  isExpanded?: boolean;
};

export const Accordion: React.FC<AccordionProps> = ({
  children,
  headerContent,
  isExpanded: contentIsExpanded = false,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(contentIsExpanded);
  useEffect(() => {
    setIsExpanded(contentIsExpanded);
  }, [contentIsExpanded]);
  return (
    <>
      <HeaderBox
        onClick={() => {
          setIsExpanded(!isExpanded);
          if (headerRef && headerRef.current) {
            headerRef.current.blur();
          }
        }}
        isExpanded={isExpanded}
        data-testid="HeaderBox"
        tabIndex={0}
        ref={headerRef}
        onKeyDown={(event) => {
          if (
            !isExpanded &&
            (event.key === 'ArrowDown' || event.key === 'Enter')
          ) {
            setIsExpanded(!isExpanded);
            if (headerRef && headerRef.current) {
              headerRef.current.blur();
            }
          } else if (
            isExpanded &&
            (event.key === 'ArrowUp' || event.key === 'Enter')
          ) {
            setIsExpanded(!isExpanded);
          }
        }}
      >
        <HeaderContentWrapper data-testid="HeaderContentWrapper">
          <HeaderContent>{headerContent}</HeaderContent>
          <ExpansionWrapper data-testid="ExpansionWrapper">
            <ExpansionIcon
              src={isExpanded ? iconCollapseAccordion : iconExpandAccordion}
            />
          </ExpansionWrapper>
        </HeaderContentWrapper>
      </HeaderBox>
      <ContentAnimationWrapper>
        <ContentWrapper
          data-testid="ContentWrapper"
          initial="collapsed"
          animate={isExpanded ? 'expanded' : 'collapsed'}
          variants={{
            expanded: {
              height: 'auto',
              overflow: 'auto',
              opacity: 1,
              transition: {
                duration: 0.05,
              },
            },
            collapsed: {
              height: '0',
              opacity: 0,
              transition: {
                duration: 0.3,
              },
            },
          }}
        >
          <Content
            data-testid="Content"
            variants={{
              collapsed: {
                opacity: 0,
                overflow: 'hidden',
                height: 0,
                transition: {
                  duration: 0.3,
                },
              },
              expanded: {
                height: 'auto',
                overflow: 'auto',
                opacity: 1,
                transition: {
                  duration: 0.4,
                },
              },
            }}
          >
            {children}
          </Content>
        </ContentWrapper>
      </ContentAnimationWrapper>
    </>
  );
};

export default Accordion;
