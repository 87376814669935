export const sizes = {
  xxxsmall: 400,
  xxsmall: 600,
  iPad: 768,
  xsmall: 780,
  small: 815,
  smallIsh: 1000,
  medium: 1440,
  large: 1600,
};

export const media = {
  xxxsmallOnly: `@media screen and (max-width: ${sizes.xxxsmall - 1}px)`,
  xxsmallUp: `@media screen and (min-width: ${sizes.xxsmall}px)`,
  xxsmallOnly: `@media screen and (max-width: ${sizes.xxsmall - 1}px)`,
  xsmallUp: `@media screen and (min-width: ${sizes.xsmall}px)`,
  xsmallOnly: `@media screen and (max-width: ${sizes.xsmall - 1}px)`,
  smallUp: `@media screen and (min-width: ${sizes.small}px)`,
  smallOnly: `@media screen and (max-width: ${sizes.small - 1}px)`,
  iPadUp: `@media screen and (min-width: ${sizes.iPad}px)`,
  iPadUpOnly: `@media screen and (max-width: ${sizes.iPad - 1}px)`,
  smallIshUp: `@media screen and (min-width: ${sizes.smallIsh}px)`,
  mediumUp: `@media screen and (min-width: ${sizes.medium}px)`,
  largeUp: `@media screen and (min-width: ${sizes.large}px)`,
};

export default media;
