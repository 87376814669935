import React, { useRef, useState } from 'react';
import iconChevron from '../../assets/icon-chevron.svg';
import CollapsedSubItem from './CollapsedSubItem';
import { MenuItem } from './SideNav';
import {
  ListItem,
  ItemButton,
  ItemIcon,
  ItemChevron,
  CollapsedTooltipWrapper,
  CollapsedTooltip,
  CollapsedSubListWrapper,
  CollapsedSubList,
  SubList,
} from './SideNavStyles';
import SubItem from './SubItem';

export type ItemProps = {
  navIsExpanded: boolean;
  item: MenuItem;
  itemsExpanded: Set<MenuItem>;
  itemSelected: MenuItem | undefined;
  selectItem: (item: MenuItem) => void;
  expandItemInExpandedNav: (item: MenuItem) => void;
  expandItemInCollapsedNav: (item: MenuItem) => void;
};

export const Item: React.FC<ItemProps> = ({
  item,
  navIsExpanded,
  itemsExpanded,
  itemSelected,
  selectItem,
  expandItemInCollapsedNav,
  expandItemInExpandedNav,
}) => {
  const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);
  const ref = useRef<HTMLButtonElement>(null);
  const handleItemClick = (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
    if (!item.items) {
      selectItem(item);
    } else if (navIsExpanded) {
      expandItemInExpandedNav(item);
    } else {
      expandItemInCollapsedNav(item);
    }
  };
  return (
    <ListItem
      key={`MainNavigation-${item.value}`}
      data-tip={item.label}
      onMouseEnter={() => {
        if (!navIsExpanded && !itemsExpanded.has(item)) {
          setTooltipIsVisible(true);
        }
      }}
      onMouseLeave={() => {
        setTooltipIsVisible(false);
      }}
      data-testid={`listItem-${item.value}`}
    >
      <ItemButton
        type="button"
        onClick={(event) => {
          handleItemClick(event);
          if (ref.current) {
            ref.current.blur();
          }
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleItemClick(event);
          }
        }}
        ref={ref}
        tabIndex={0}
        selected={itemSelected === item}
        expanded={itemsExpanded.has(item)}
        navIsExpanded={navIsExpanded}
        subitemselected={Boolean(item.items?.some((i) => i === itemSelected))}
        data-testid={`itemButton-${item.value}`}
      >
        <ItemIcon src={item.imgSrc as string} />
        {navIsExpanded && item.label}
        {navIsExpanded && item.items && (
          <ItemChevron
            src={iconChevron}
            expanded={itemsExpanded.has(item)}
            subitemselected={Boolean(
              item.items?.some((i) => i === itemSelected)
            )}
            data-testid={`itemChevron-${item.value}`}
          />
        )}
      </ItemButton>
      {tooltipIsVisible && (
        <CollapsedTooltipWrapper
          data-testid={`collapsedTooltipWrapper-${item.value}`}
        >
          <CollapsedTooltip data-testid={`collapsedTooltip-${item.value}`}>
            <span>{item.label}</span>
          </CollapsedTooltip>
        </CollapsedTooltipWrapper>
      )}
      {item.items && !navIsExpanded && itemsExpanded.has(item) && (
        <CollapsedSubListWrapper
          expanded={itemsExpanded.has(item)}
          data-testid={`collapsedSubListWrapper-${item.value}`}
        >
          <CollapsedSubList data-testid={`collapsedSubList-${item.value}`}>
            {item.items.map((subitem) => (
              <CollapsedSubItem
                expanded={itemsExpanded.has(item)}
                selected={itemSelected === subitem}
                selectItem={selectItem}
                item={item}
                subItem={subitem}
              />
            ))}
          </CollapsedSubList>
        </CollapsedSubListWrapper>
      )}
      {item.items && navIsExpanded && (
        <SubList
          key={`item-${item.value}`}
          data-testid={`subList-${item.value}`}
          expanded={itemsExpanded.has(item)}
          itemCount={item.items.length || 0}
        >
          {item.items.map((subitem) => (
            <SubItem
              key={`subitem-${subitem.value}`}
              expanded={itemsExpanded.has(item)}
              selected={itemSelected === subitem}
              navIsExpanded={navIsExpanded}
              selectItem={selectItem}
              item={item}
              subItem={subitem}
            />
          ))}
        </SubList>
      )}
    </ListItem>
  );
};

export default Item;
