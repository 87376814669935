import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { ComponentWrapper, MenuWrapper } from './DropdownStyles';
import MenuItem from './MenuItem';

export type MenuItemDefinition = {
  label: string;
  subItems?: MenuItemDefinition[];
  hasWarningStyle?: boolean;
};

export type DropdownMenuProps = {
  initialActiveItem?: MenuItemDefinition | undefined;
  isRightAligned?: boolean;
  menuItems: MenuItemDefinition[];
  onSelect: (menuItem: MenuItemDefinition) => void;
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  initialActiveItem,
  isRightAligned = false,
  menuItems,
  onSelect,
}) => {
  const [activeItem, setActiveItem] = useState<MenuItemDefinition | undefined>(
    initialActiveItem
  );
  return (
    <ComponentWrapper
      data-testid="Dropdown__Wrapper"
      onMouseLeave={() => {
        setActiveItem(undefined);
      }}
    >
      <MenuWrapper isRightAligned={isRightAligned}>
        {menuItems.map((menuItem) => {
          return (
            <MenuItem
              key={uuid()}
              activeItem={activeItem}
              isRightAligned={isRightAligned}
              menuItem={menuItem}
              setActiveItem={setActiveItem}
              onSelect={onSelect}
            />
          );
        })}
      </MenuWrapper>
    </ComponentWrapper>
  );
};

export default DropdownMenu;
