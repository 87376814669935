/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, ReactElement } from 'react';
import styled from 'styled-components';
import filterFactory from './filterFactory';
import FilterButton from './FilterButton';

const FilterSideBarWrapper = styled.div`
  flex-shrink: 0;
  box-shadow: -2px 0 5px 0 rgba(230, 232, 237, 0.5);
  padding: 20px 0px;
  background-color: rgba(244, 247, 251, 0.4);
`;

const FilterGroupWrapper = styled.div`
  margin-bottom: 1.2rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const FilterGroupTitle = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  padding-left: 26px;
  color: rgba(36, 45, 65, 0.9);
  a {
    font-size: 0.9rem;
    text-transform: initial;
    font-weight: 300;
  }
`;

export type FilterSideBarProps<T> = {
  updateArray?: (results: T[]) => void;
  data: T[];
  onChange?: (filters: FilterDefinition<T>[]) => void;
  filterGroups: FilterGroup<T>[];
};

export type FilterDefinition<T> = {
  label: string;
  key: string;
  value: string;
  badge?: string;
  filterFunction: (item: T) => void;
};

export type FilterGroup<T> = {
  groupLabel: string;
  filters: FilterDefinition<T>[];
};

export const FilterSideBar = <T,>({
  updateArray,
  data,
  filterGroups,
  onChange,
}: FilterSideBarProps<T>): ReactElement => {
  const [filterStates, updateFilterStates] = useState<FilterDefinition<T>[]>(
    []
  );

  useEffect(() => {
    const filters = filterFactory({
      data,
      filterStates,
      filterGroups,
    });
    if (onChange) {
      onChange(filterStates);
    }
    if (updateArray) {
      filters.produceResults();
      if (filters.haveSetOfResults()) {
        updateArray(filters.getSetOfResults());
      }
    }
  }, [filterStates, data.length]);

  const createFilterStates = ({
    filter,
    filterState,
  }: {
    filter: FilterDefinition<T>;
    filterState: boolean;
  }) => {
    let newFilterStates: FilterDefinition<T>[] = [...filterStates];
    const filterShouldBeApplied = !newFilterStates.includes(filter);
    const filterShouldBeRemoved = filterState === false;

    if (filterShouldBeApplied) {
      newFilterStates.push(filter);
    }
    if (filterShouldBeRemoved) {
      newFilterStates = newFilterStates.filter((item) => item !== filter);
    }
    updateFilterStates(newFilterStates);
  };

  return (
    <FilterSideBarWrapper data-testid="filterSidebarWrapper">
      {filterGroups.map((filterGroup, i) => (
        <FilterGroupWrapper key={i} data-testid={`filterGroupWrapper-${i}`}>
          <FilterGroupTitle data-testid={`filterGroupTitle-${i}`}>
            {filterGroup.groupLabel}
          </FilterGroupTitle>
          {filterGroup.filters.map((filter, j) => (
            <FilterButton
              filter={filter}
              updateFilterState={createFilterStates}
              filterStates={filterStates}
              key={`Filter-${filter.value}`}
              count={data.filter((item) => filter.filterFunction(item)).length}
              data-testid={`group-${i}-filterButton-${j}`}
            />
          ))}
        </FilterGroupWrapper>
      ))}
    </FilterSideBarWrapper>
  );
};

export default FilterSideBar;
