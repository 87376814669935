/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useState, useEffect } from 'react';
import { Row, TableInstance } from 'react-table';
import SortingArrowSvg from '../../assets/icon-sort.svg';
import ArrowSvg from '../../assets/arrow.svg';
import {
  ColumnHead,
  HeadRow,
  Table,
  TableHead,
  TableBody,
  TableRow,
  RowCell,
  SorterArrow,
  PaginationWrapper,
  PaginationButton,
  PageNumberInput,
  PageCountText,
  PageNavigationWrapper,
  BackPaginationArrow,
  ForwardPaginationArrow,
  TableComponentWrapper,
  PaginationDivider,
} from './TableStyling';
import PaginationDropdown from './PaginationDropdown';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface TableProps<T extends object = {}> {
  tableInstance: TableInstance<T>;
  handleRowClick?: (row: Row<T>) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const TFTable = <T extends object = {}>({
  tableInstance,
  handleRowClick,
}: TableProps<T>): ReactElement => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    pageCount,
    rows,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = tableInstance;
  const [currentPage, setCurrentPage] = useState<number>(pageIndex + 1);

  useEffect(() => {
    if (currentPage) {
      gotoPage(currentPage - 1);
    }
  }, [currentPage, gotoPage]);

  useEffect(() => {
    if (pageSize * (currentPage + 1) > rows.length) {
      setCurrentPage(pageCount);
    }
  }, [pageIndex, pageCount, pageSize, currentPage, rows.length]);

  return (
    <TableComponentWrapper>
      <Table
        data-testid="table"
        {...getTableProps()}
        autoResetHiddenColumns={false}
      >
        <TableHead data-testid="tableHead">
          {headerGroups.map((headerGroup, i) => (
            <HeadRow
              {...headerGroup.getHeaderGroupProps()}
              data-testid={`headRow${i}`}
            >
              {headerGroup.headers.map((column, j) => (
                <ColumnHead
                  width={column.width}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  data-testid={`headRow${i}-column${j}`}
                >
                  {column.render('Header')}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <SorterArrow src={SortingArrowSvg} alt="arrow" up />
                    ) : (
                      <SorterArrow src={SortingArrowSvg} alt="arrow" />
                    )
                  ) : null}
                </ColumnHead>
              ))}
            </HeadRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()} data-testid="tableBody">
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow
                tabIndex={0}
                onClick={() => handleRowClick && handleRowClick(row)}
                {...row.getRowProps()}
                data-testid={`tableRow${i}`}
              >
                {row.cells.map((cell) => {
                  return (
                    <RowCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </RowCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <PaginationDivider />
      <PaginationWrapper data-testid="paginationWrapper">
        <div />
        <div>
          <PaginationDropdown pageSize={pageSize} setPageSize={setPageSize} />
        </div>
        <div>
          <PageNavigationWrapper data-testid="pageNavigationWrapper">
            <PaginationButton
              onClick={() => {
                if (currentPage) {
                  setCurrentPage(currentPage - 1);
                } else {
                  // page index is offset by 1 so we just pass it along as is
                  setCurrentPage(pageIndex);
                }
              }}
              disabled={!canPreviousPage}
              data-testid="backPaginationButton"
            >
              <BackPaginationArrow src={ArrowSvg} />
            </PaginationButton>
            <PageNumberInput
              value={currentPage}
              onKeyPress={(event): void => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(event): void => {
                if (event.target.value === '') {
                  setCurrentPage(currentPage);
                  return;
                }
                if (parseInt(event.target.value, 10) > pageCount) {
                  setCurrentPage(pageCount);
                } else {
                  setCurrentPage(parseInt(event.target.value, 10));
                }
              }}
              data-testid="pageNumberInput"
            />
            <PageCountText data-testid="pageCountText">
              of {pageOptions.length}
            </PageCountText>
            <PaginationButton
              onClick={() => {
                if (currentPage) {
                  setCurrentPage(currentPage + 1);
                } else {
                  // want to rely on pageIndex which is offset by 1 so + 2
                  setCurrentPage(pageIndex + 2);
                }
              }}
              disabled={!canNextPage}
              data-testid="forwardPaginationButton"
            >
              <ForwardPaginationArrow src={ArrowSvg} />
            </PaginationButton>
          </PageNavigationWrapper>
        </div>
      </PaginationWrapper>
    </TableComponentWrapper>
  );
};

export default TFTable;
