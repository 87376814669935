import React, { useEffect, useState } from 'react';
import { Descendant, Editor } from 'slate';
import styled from 'styled-components';
import Bold from '../../assets/RichTeIcons/icon-bold.svg';
import BoldActive from '../../assets/RichTeIcons/icon-bold-active.svg';
import Italic from '../../assets/RichTeIcons/icon-italic.svg';
import ItalicActive from '../../assets/RichTeIcons/icon-italic-active.svg';
import HighlightColour from '../../assets/RichTeIcons/icon-highlight-blank.svg';
import TextColourLetterBlack from '../../assets/RichTeIcons/icon-text-colour-black.svg';
import ClearColorButton from './ColorMenu/ClearColorButton';
import ColorButton from './ColorMenu/ColorButton';
import ColorMenu from './ColorMenu/ColorMenu';
import MarkButton from './MarkButton';
import RedoButton from './RedoButton';
import SanitiseTool from './SanitiseTool';
import TableButton from './TableButton';
import { toolBarColours } from './ToolBarStyles';
import UndoButton from './UndoButton';
import BlockButtons from './BlockButtons';

type RichTextToolbarProps = {
  name: string;
  onBlur: (overrideFocus: boolean) => void;
  editor: Editor;
  resetMenus: boolean;
  value: Node[] | Descendant[];
};

const ToolBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const Divider = styled.span`
  width: 1px;
  height: 16px;
  background-color: ${({ theme }): string => theme.colors.frostedSteel};
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
`;

const UndoRedoWrapper = styled.div`
  display: flex;
  flex: 1 0 10%;
  justify-content: flex-end;
`;

export default ({
  name,
  onBlur,
  editor,
  resetMenus,
  value,
}: RichTextToolbarProps): JSX.Element => {
  const [highlightMenu, toggleHighlightMenu] = useState(false);
  const [textMenu, toggleTextMenu] = useState(false);
  const [tableMenu, createAndEditTableMenu] = useState(false);

  useEffect(() => {
    toggleHighlightMenu(false);
    toggleTextMenu(false);
    createAndEditTableMenu(false);
  }, [resetMenus]);

  return (
    <ToolBarWrapper data-testid="ToolBar">
      <MarkButton
        format="bold"
        icon={Bold}
        activeIcon={BoldActive}
        data-testid="BoldButton"
      />
      <MarkButton
        format="italic"
        icon={Italic}
        activeIcon={ItalicActive}
        data-testid="ItalicButton"
      />
      <Divider />
      <ColorMenu
        showMenu={textMenu}
        menuToggle={toggleTextMenu}
        toggleOtherMenus={{
          siblingColourMenu: toggleHighlightMenu,
          tableMenu: createAndEditTableMenu,
        }}
        otherMenuStates={[highlightMenu, tableMenu]}
        type="text"
        label="Text color"
        icon={TextColourLetterBlack}
      >
        <ColorButton
          type="text"
          format={`textColour${toolBarColours.text.black}`}
        />
        <ColorButton
          type="text"
          format={`textColour${toolBarColours.text.blue}`}
        />
        <ColorButton
          type="text"
          format={`textColour${toolBarColours.text.yellow}`}
        />
      </ColorMenu>
      <ColorMenu
        type="highlight"
        showMenu={highlightMenu}
        menuToggle={toggleHighlightMenu}
        toggleOtherMenus={{
          siblingColourMenu: toggleTextMenu,
          tableMenu: createAndEditTableMenu,
        }}
        otherMenuStates={[textMenu, tableMenu]}
        label="Highlight color"
        icon={HighlightColour}
      >
        <ColorButton
          type="highlight"
          format={`highlightColour${toolBarColours.highlight.green}`}
        />
        <ColorButton
          type="highlight"
          format={`highlightColour${toolBarColours.highlight.flYellow}`}
        />
        <ColorButton
          type="highlight"
          format={`highlightColour${toolBarColours.highlight.pink}`}
        />
        <ClearColorButton type="highlight" />
      </ColorMenu>
      <Divider />
      <BlockButtons />
      <Divider />
      <TableButton
        format="table-row"
        showMenu={tableMenu}
        toggleTextMenu={toggleTextMenu}
        toggleHighlightMenu={toggleHighlightMenu}
        otherMenuStates={[textMenu, highlightMenu]}
        menuToggle={createAndEditTableMenu}
        onBlur={onBlur}
      />
      <Divider />
      <SanitiseTool name={name} />
      <UndoRedoWrapper>
        <UndoButton editor={editor} value={value} />
        <RedoButton editor={editor} value={value} />
      </UndoRedoWrapper>
    </ToolBarWrapper>
  );
};
