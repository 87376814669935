import React, { Fragment, useRef } from 'react';
import ChapterButton from './ChapterButton';
import { SectionTitle, ChapterButtonsWrapper } from './ContentsMenuStyles';

export type ContentMenuItem = {
  title: string;
};

export type ContentSection = {
  title: string;
  items: ContentMenuItem[];
};

type SectionMenuProps = {
  content: ContentSection;
  handleSectionClick: (content: ContentSection) => void;
  itemSelected: ContentMenuItem | undefined;
  isExpanded: boolean;
  subItemSelected: boolean;
  setItemSelected: (content: ContentMenuItem) => void;
  onSelect: (content: ContentMenuItem) => void;
};

const SectionMenu = ({
  content,
  handleSectionClick,
  itemSelected,
  isExpanded,
  subItemSelected,
  setItemSelected,
  onSelect,
}: SectionMenuProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <Fragment key={`fragment-${content.title}`}>
      <SectionTitle
        data-testid={`SectionTitle-${content.title}`}
        onClick={() => {
          handleSectionClick(content);
          if (ref && ref.current) {
            ref.current.blur();
          }
        }}
        isExpanded={isExpanded}
        subItemSelected={subItemSelected}
        tabIndex={0}
        ref={ref}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleSectionClick(content);
          }
        }}
      >
        {content.title}
      </SectionTitle>
      {content.items && (
        <ChapterButtonsWrapper
          data-testid={`ChapterButtonsWrapper-${content.title}`}
          isExpanded={isExpanded}
          itemCount={content.items.length}
        >
          {content.items.map((item) => {
            return (
              <ChapterButton
                content={content}
                item={item}
                isActive={itemSelected === item}
                onClick={() => {
                  setItemSelected(item);
                  onSelect(item);
                }}
                key={`${content.title}-chapterbutton-${item.title}`}
                data-testid={`ChapterButton-${item.title}`}
              />
            );
          })}
        </ChapterButtonsWrapper>
      )}
    </Fragment>
  );
};

export default SectionMenu;
