import { motion } from 'framer-motion';
import styled from 'styled-components/macro';
import { trustFlight } from '../../styles/themes';

export const CloseModalBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 40px;
`;

export const CloseModalIcon = styled.img`
  width: 12px;
  height: auto;
`;

export const ModalCard = styled(motion.div)`
  background-color: ${({ theme }): string => theme.colors.snow};
  border: 2px solid ${({ theme }): string => theme.colors.white};
  border-radius: 2px;
  box-shadow: 0 0 10px 0 ${({ theme }): string => theme.colors.black20Alpha};
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 280px;
  max-height: calc(100% - 100px);
  overflow-y: auto;
  position: relative;
  display: flex;
  align-items: center;
  ${(): string => trustFlight.media.smallUp} {
    width: 600px;
  }
`;
export const ModalCardVariants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  enter: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 50,
  },
};

export const ModalContent = styled.div`
  height: 100%;
  max-height: calc(100vh - 118px);
  width: 100%;
`;

type ModalWrapperProps = {
  isModalOpen: boolean;
};

export const ModalWrapper = styled(motion.div)<ModalWrapperProps>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  background: ${({ theme }): string => theme.colors.black40Alpha};
  display: ${({ isModalOpen }): string => (isModalOpen ? 'flex' : 'none')};
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 999;
`;
export const ModalWrapperVariants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};
