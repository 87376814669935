import React from 'react';
import styled from 'styled-components';

const ToolTipWrapper = styled.span``;

export const ToolTipHover = styled.div<{
  isVisible: boolean;
}>`
  font-size: 12px;
  white-space: nowrap;
  position: absolute;
  top: 40px;
  height: 25px;
  background-color: ${({ theme }): string => theme.colors.navyDark};
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 2px;
  padding-left: 5px;
  padding-right: 5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: ${({ isVisible }): string => (isVisible ? 'flex;' : 'none;')};
  @media only screen and (max-width: 1050px) {
    display: none;
  }
  &::after {
    content: ' ';
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent
      ${({ theme }): string => theme.colors.navyDark} transparent;
  }
`;

type ToolTipProps = {
  children: JSX.Element | JSX.Element[];
  text: string;
  isVisible: boolean;
};

export default ({ children, text, isVisible }: ToolTipProps): JSX.Element => {
  const capitalizeFirstLetter = text.replace(
    text.charAt(0),
    text.charAt(0).toUpperCase()
  );

  return (
    <ToolTipWrapper>
      <ToolTipHover isVisible={isVisible}>{capitalizeFirstLetter}</ToolTipHover>
      {children}
    </ToolTipWrapper>
  );
};
