import { AnimatePresence } from 'framer-motion';
import React, { FunctionComponent, useState } from 'react';
import deleteIcon from '../../assets/delete-icon-x.svg';
import {
  CloseModalBtn,
  CloseModalIcon,
  ModalCard,
  ModalCardVariants,
  ModalContent,
  ModalWrapper,
  ModalWrapperVariants,
} from './modalStyles';

export type ModalProps = {
  children?: React.ReactNode;
  handleClose: () => void;
  isOpen: boolean;
  shouldCloseOnOuterClick?: boolean;
};
const Modal: FunctionComponent<ModalProps> = ({
  children,
  shouldCloseOnOuterClick = true,
  handleClose,
  isOpen,
}) => {
  const [isModalShowing, setIsModalShowing] = useState(isOpen);

  const handleModalClose = (): void => {
    setIsModalShowing(false);
    handleClose();
  };

  return (
    <AnimatePresence>
      {(isModalShowing || isOpen) && (
        <ModalWrapper
          data-testid="ModalWrapper"
          variants={ModalWrapperVariants}
          initial="initial"
          animate="enter"
          exit="exit"
          isModalOpen={isOpen}
          onClick={shouldCloseOnOuterClick ? handleModalClose : undefined}
        >
          <ModalCard
            data-testid="ModalCard"
            animate="enter"
            exit="exit"
            initial="initial"
            onClick={(e): void => e.stopPropagation()}
            variants={ModalCardVariants}
          >
            <ModalContent data-testid="ModalContent">
              <CloseModalBtn
                data-testid="CloseModalBtn"
                onClick={handleModalClose}
              >
                <CloseModalIcon src={deleteIcon} alt="Close modal button" />
              </CloseModalBtn>
              {children}
            </ModalContent>
          </ModalCard>
        </ModalWrapper>
      )}
    </AnimatePresence>
  );
};

export default Modal;
