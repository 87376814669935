import { motion } from 'framer-motion';
import InlineSvg from 'react-inlinesvg';
import styled from 'styled-components';

export const ButtonMenuWrapper = styled.div<{ isRightAligned: boolean }>`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: ${({ isRightAligned }): string =>
    isRightAligned ? 'flex-end' : 'flex-start'};
  .button-content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .button-wrapper {
    display: flex;
    justify-content: ${({ isRightAligned }): string =>
      isRightAligned ? 'flex-end' : 'flex-start'};
    width: 145px;
    margin-bottom: 8px;
    margin-right: 10px;
  }
`;

export const ButtonDropdownIcon = styled.img<{ isOpen: boolean }>`
  height: auto;
  margin-left: 10px;
  transition: all 300ms;
  transform: ${({ isOpen }): string => (isOpen ? 'rotate(180deg)' : 'none')};
  width: 6px;
`;

export const ComponentWrapper = styled(motion.div)`
  display: inline-block;
  position: relative;
  z-index: 1;
`;

export const ItemChevron = styled(InlineSvg)<{
  isExpanded?: boolean;
  $hasWarningStyle?: boolean;
  $isRightAligned: boolean;
}>`
  width: 12px;
  height: 12px;
  position: absolute;
  right: ${({ $isRightAligned }): string =>
    $isRightAligned ? '15px' : '20px;'};
  fill: ${({ $hasWarningStyle, theme }) =>
    $hasWarningStyle ? theme.colors.red90Alpha : theme.colors.black};
`;

export const MenuItemWrapper = styled.div<{
  isRightAligned: boolean;
  hasWarningStyle?: boolean;
}>`
  align-items: center;
  background-color: white;
  border: none;
  color: ${({ hasWarningStyle, theme }) =>
    hasWarningStyle ? theme.colors.red90Alpha : theme.colors.black80Alpha};
  display: flex;
  flex-direction: ${({ isRightAligned }) =>
    isRightAligned ? 'row-reverse' : 'row'};
  font-size: 14px;
  font-weight: 500;
  justify-content: ${({ isRightAligned }): string =>
    isRightAligned ? 'flex-end' : 'space-between'};
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  pointer-events: hover;
  text-align: left;
  &:hover {
    cursor: pointer;
    color: ${({ hasWarningStyle, theme }) =>
      hasWarningStyle ? theme.colors.red90Alpha : theme.colors.brandBlue};
    background-color: ${({ hasWarningStyle, theme }): string =>
      hasWarningStyle ? theme.colors.red5Alpha : theme.colors.brandBlue05Alpha};
  }
  &:hover ${ItemChevron} {
    fill: ${({ theme, hasWarningStyle }) =>
      hasWarningStyle ? theme.colors.red90Alpha : theme.colors.brandBlue};
  }
  &:focus {
    box-shadow: ${({ hasWarningStyle, theme }) =>
      hasWarningStyle
        ? `inset 0 0 0 1px ${(): string => theme.colors.brandBlue}`
        : `inset 0 0 0 1px ${(): string => theme.colors.red90Alpha}`};
    background-color: ${({ theme }): string => theme.colors.salt};
    color: ${({ theme, hasWarningStyle }) =>
      hasWarningStyle ? theme.colors.red90Alpha : theme.colors.brandBlue};
  }
  &:active {
    background-color: ${({ hasWarningStyle, theme }): string =>
      hasWarningStyle
        ? theme.colors.red10Alpha
        : theme.colors.brandBlue10Alpha};
    color: ${({ hasWarningStyle, theme }): string =>
      hasWarningStyle ? theme.colors.red90Alpha : theme.colors.brandBlue};
  }
`;

export const MenuWrapper = styled.div<{
  isRightAligned: boolean;
}>`
  background: ${({ theme }): string => theme.colors.white};
  border: 1px solid ${({ theme }): string => theme.colors.white};
  border-radius: 4px;
  box-shadow: 0 0 10px 5px ${({ theme }): string => theme.colors.frostedSteel};
  font-size: 13px;
  ${({ isRightAligned }) =>
    isRightAligned ? 'margin-left: 10px;' : 'margin-right 10px;'};
  width: 270px;
  span {
    width: 90%;
  }
`;

export const SubItemMenu = styled(MenuWrapper)`
  max-height: 300px;
  overflow: auto;
`;

export const SubItemsWrapper = styled.div<{ isRightAligned: boolean }>`
  position: absolute;
  ${({ isRightAligned }): string => {
    return isRightAligned ? 'right: 10px;' : 'left: 280px;';
  }};
  top: -38px;
`;

export const SubMenuWrapper = styled(motion.div)`
  position: absolute;
  z-index: 1;
`;
