/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */

import { AnimatePresence } from 'framer-motion';
import { some } from 'lodash';
import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import iconChevron from '../../assets/icon-chevron.svg';
import TruncateString from '../../utils/TruncateString';
import { MenuItemDefinition } from './DropdownMenu';
import {
  ItemChevron,
  MenuItemWrapper,
  SubItemMenu,
  SubItemsWrapper,
  SubMenuWrapper,
} from './DropdownStyles';
import subMenuVariants from './subMenuVariants';

type SubmenuProps = {
  parentMenuItem: MenuItemDefinition;
  subItems: MenuItemDefinition[];
  onSelect: (menuItem: MenuItemDefinition) => void;
  isRightAligned: boolean;
  hasWarningStyle?: boolean;
};

const Submenu: React.FC<SubmenuProps> = ({
  parentMenuItem,
  isRightAligned,
  onSelect,
  subItems,
  hasWarningStyle = false,
}) => {
  const [activeItem, setActiveItem] = useState<
    MenuItemDefinition | undefined
  >();
  return (
    <SubItemsWrapper isRightAligned={isRightAligned}>
      <SubItemMenu isRightAligned={isRightAligned}>
        {subItems.map((subItem) => {
          return (
            <MenuItem
              key={`subMenuItem-${parentMenuItem.label}-${subItem.label}`}
              activeItem={activeItem}
              menuItem={subItem}
              setActiveItem={setActiveItem}
              onSelect={onSelect}
              isRightAligned={isRightAligned}
              parentHasWarningStyle={hasWarningStyle}
            />
          );
        })}
      </SubItemMenu>
    </SubItemsWrapper>
  );
};

type MenuItemProps = {
  activeItem?: MenuItemDefinition;
  menuItem: MenuItemDefinition;
  setActiveItem: (item: MenuItemDefinition | undefined) => void;
  onSelect: (menuItem: MenuItemDefinition) => void;
  isRightAligned: boolean;
  parentHasWarningStyle?: boolean;
};

const MenuItem: React.FC<MenuItemProps> = ({
  activeItem,
  setActiveItem,
  menuItem,
  isRightAligned,
  onSelect,
  parentHasWarningStyle = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (activeItem === menuItem) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [activeItem, menuItem]);

  const { label, subItems, hasWarningStyle } = menuItem;

  const hasSubItems = subItems && some(subItems);

  const handleSelect = () => {
    if (!hasSubItems) {
      setActiveItem(undefined);
      setIsExpanded(false);
      onSelect(menuItem);
    }
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (isExpanded) {
          setIsExpanded(false);
        }
      }}
    >
      <MenuItemWrapper
        data-testid={`MenuItemWrapper__${label}`}
        tabIndex={0}
        key={`MenuItem__${label}`}
        hasWarningStyle={parentHasWarningStyle || hasWarningStyle}
        isRightAligned={isRightAligned}
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
          const { key } = event;
          if (!hasSubItems) {
            if (key === 'Enter') {
              handleSelect();
            }
          }
          if (hasSubItems) {
            if (key === 'ArrowRight' || key === 'Enter')
              setActiveItem(menuItem);
          }
        }}
        onMouseEnter={() => {
          setActiveItem(menuItem);
        }}
        onClick={() => {
          handleSelect();
        }}
      >
        <span>
          <TruncateString maxWidth="270px">{label}</TruncateString>
        </span>
        {hasSubItems && (
          <ItemChevron
            src={iconChevron}
            $hasWarningStyle={parentHasWarningStyle || hasWarningStyle}
            $isRightAligned={isRightAligned}
          />
        )}
      </MenuItemWrapper>
      <AnimatePresence exitBeforeEnter>
        {subItems && isExpanded && (
          <SubMenuWrapper
            variants={subMenuVariants}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            <Submenu
              parentMenuItem={menuItem}
              subItems={subItems}
              onSelect={onSelect}
              isRightAligned={isRightAligned}
              hasWarningStyle={parentHasWarningStyle || hasWarningStyle}
            />
          </SubMenuWrapper>
        )}
      </AnimatePresence>
    </OutsideClickHandler>
  );
};

export default MenuItem;
