import React, { memo, useEffect, useState } from 'react';
import { Descendant, Editor } from 'slate';
import { ReactEditor } from 'slate-react';
import UndoIconSvg from '../../assets/RichTeIcons/icon-undo.svg';
import {
  ToolButton,
  ToolButtonWrapper,
  HistoryButtonIcon,
} from './ToolBarStyles';
import ToolTip from './ToolTip';

export default memo(
  ({ editor, value }: { editor: Editor; value: Node[] | Descendant[] }) => {
    const [isVisibleTooltip, setTooltipIsVisible] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const handleUndo = (): void => {
      try {
        editor.undo();
        ReactEditor.focus(editor);
      } catch (e) {
        console.error(e);
      }
    };

    useEffect(() => {
      if (editor.history.undos.length === 0) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }, [value, editor.history.undos.length]);

    return (
      <ToolButtonWrapper>
        <ToolTip text="undo" isVisible={isVisibleTooltip}>
          <ToolButton
            onClick={handleUndo}
            data-testid="UndoButton"
            onMouseEnter={() => {
              setTooltipIsVisible(true);
            }}
            onMouseLeave={() => {
              setTooltipIsVisible(false);
            }}
            disabled={disabled}
          >
            <HistoryButtonIcon
              src={UndoIconSvg}
              alt="block button"
              disabled={disabled}
            />
          </ToolButton>
        </ToolTip>
      </ToolButtonWrapper>
    );
  }
);
