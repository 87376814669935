import styled from 'styled-components';
import ReactSelect from 'react-select';
import InlineSvg from 'react-inlinesvg';

import media from '../../styles/mediaQueries';

type TableProps = {
  autoResetHiddenColumns: boolean;
};

export const ComponentWrapper = styled.div`
  display: flex;
  overflow-y: visible;
`;

export const TableComponentWrapper = styled.span``;

export const TableWrapper = styled.div`
  padding-left: 28px;
  padding-right: 28px;
  flex: auto;
  overflow-y: visible;
  overflow-x: scroll;
`;

export const SearchWrapper = styled.div`
  float: right;
  padding-top: 28px;
  padding-bottom: 24px;
`;

export const Table = styled.table<TableProps>`
  border-spacing: 0 10px;
  border-collapse: separate;
  width: 100%;
`;

export const TableHead = styled.thead``;

export const HeadRow = styled.tr``;

type ColumnHeadProps = {
  width: string | number | undefined;
};

export const ColumnHead = styled.th<ColumnHeadProps>`
  background-color: transparent;
  color: ${({ theme }): string => theme.colors.black30Alpha};
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.33px;
  border: none;
  text-transform: uppercase;
  border-spacing: 0;
  padding-top: 0px;
  padding-left: 10px;
  flex-shrink: 0;
  text-align: left;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  box-shadow: 0 0 10px 0
    ${({ theme }): string => theme.colors.frostedSteel40Alpha};
  margin: 0 5px;
  height: 40px;
  border-radius: 4px;
  background-color: ${({ theme }): string => theme.colors.salt};
  font-size: 13px;
  color: ${({ theme }): string => theme.colors.black90Alpha};
  &:last-child {
    margin-bottom: 40px;
  }
  &:hover {
    box-shadow: 0 0 5px 2px
      ${({ theme }): string => theme.colors.frostedSteel80Alpha};
    background-color: ${({ theme }): string => theme.colors.brandBlue03Alpha};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${({ theme }): string => theme.colors.brandBlue};
  }
  &:active {
    outline: none;
  }
`;

export const RowCell = styled.td`
  text-align: left;
  padding: 0 8px;
  border-top: 2px solid ${({ theme }): string => theme.colors.white};
  border-bottom: 2px solid ${({ theme }): string => theme.colors.white};

  &:first-child {
    border-left: 2px solid ${({ theme }): string => theme.colors.white};
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  &:last-child {
    border-right: 2px solid ${({ theme }): string => theme.colors.white};
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

type SorterArrowProps = {
  up?: boolean;
};

export const SorterArrow = styled.img<SorterArrowProps>`
  width: 8px;
  margin-left: 4px;
  transform: ${({ up }): string => (up ? 'rotate(180deg)' : 'rotate(0)')};
`;

export const PaginationDivider = styled.div`
  width: 100%;
  border-bottom: solid 1px ${({ theme }): string => theme.colors.black05Alpha};
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const PaginationWrapper = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  ${media.smallOnly} {
    grid-template-columns: 100%;
  }
  overflow-y: visible;
`;

export const PageDropdownWrapper = styled.div`
  float: left;
  ${media.largeUp} {
    transform: translateX(66%);
  }
`;

export const PageDropdownTextandBoxWrapper = styled.div`
  display: flex;
`;

export const PageNavigationWrapper = styled.div`
  ${media.iPadUp} {
    float: right;
  }
  ${media.smallOnly} {
    float: left;
    transform: translateX(50%);
    margin-top: 5px;
  }
`;

export const ForwardPaginationArrow = styled(InlineSvg)`
  padding-left: 1px;
  padding-top: 4px;
  width: 9px;
  fill: ${({ theme }): string => theme.colors.black};
`;

export const BackPaginationArrow = styled(InlineSvg)`
  margin-right: 1px;
  width: 9px;
  fill: ${({ theme }): string => theme.colors.black};
  transform: rotate(180deg);
`;

export const PaginationButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50px;
  border-width: 1px;
  border-color: ${({ theme }): string => theme.colors.black20Alpha};
  background: transparent;
  &:disabled {
    opacity: 0.3;
  }
  &:hover:enabled {
    border: solid 1px ${({ theme }): string => theme.colors.brandBlue20Alpha};
    cursor: pointer;
    border-radius: 50px;
    svg {
      fill: ${({ theme }): string => theme.colors.brandBlue};
    }
  }
  &:focus:enabled {
    border: solid 1px ${({ theme }): string => theme.colors.black20Alpha};
    border-radius: 50px;
    outline: none;
    svg {
      fill: ${({ theme }): string => theme.colors.brandBlue};
    }
  }
  &:active:enabled {
    border: solid 1px ${({ theme }): string => theme.colors.black20Alpha};
    border-radius: 50px;
    outline: none;
    background-color: ${({ theme }): string => theme.colors.black15Alpha};
    svg {
      fill: ${({ theme }): string => theme.colors.brandBlue};
    }
  }
  margin-right: 5px;
`;

export const PageNumberInput = styled.input`
  width: 35px;
  height: 20px;
  border-radius: 16px;
  border: solid 1px ${({ theme }): string => theme.colors.black20Alpha};
  margin-right: 5px;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }): string => theme.colors.black90Alpha};
  &:focus {
    outline: none;
    border-radius: 16px;
    border: solid 1px ${({ theme }): string => theme.colors.brandBlue};
  }
`;

export const PageCountText = styled.span`
  color: ${({ theme }): string => theme.colors.black70Alpha};
  font-size: 14px;
  margin-right: 5px;
`;

export const PageDropdownText = styled.span`
  color: ${({ theme }): string => theme.colors.black70Alpha};
  font-size: 13px;
  margin-right: 3px;
  padding-top: 4px;
`;

export const DropdownIconWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const DropdownIcon = styled.img<{ isFocused: boolean }>`
  position: absolute;
  right: 0px;
  top: 10px;
  width: 8px;
  color: ${({ theme }): string => theme.colors.black90Alpha};
  padding-right: 8px;
  ${({ isFocused }): string =>
    isFocused
      ? 'transform: rotate(180deg); padding-right: 0px; padding-left: 8px;'
      : ''};
`;

export const PageSizeBox = styled.div`
  display: flex;
  font-size: 14px;
  min-height: 24px;
  height: 24px;
  width: 54px;
  box-shadow: none;
  background-color: white;
  border-radius: 16px;
  border: solid 1px ${({ theme }): string => theme.colors.black20Alpha};
  cursor: pointer;
  &:focus {
    outline: none;
    border: solid 1px ${({ theme }): string => theme.colors.brandBlue};
  }
`;

export const PageSizeLabel = styled.div`
  padding-top: 4px;
  padding-left: 12px;
  font-size: 13px;
  ${({ theme }): string => theme.colors.black90Alpha};
`;

export const PageDropdownAnchorPoint = styled.div`
  margin-left: 3px;
  margin-top: 2px;
`;

export const PageSizeMenuWrapper = styled.div<{
  isOpen: boolean;
  left: number;
  top: number;
}>`
  position: absolute;
  overflow: visible;
  left: ${({ left }): string => {
    return `${left}px`;
  }};
  top: ${({ top }): string => {
    return `${top}px`;
  }};
  width: 54px;
  background-color: ${({ theme }): string => theme.colors.white};
  border-radius: 4px;
  border: solid 1px ${({ theme }): string => theme.colors.black10Alpha};
  ${({ isOpen }): string => (!isOpen ? 'display: none' : 'display: block')};
  padding-top: 6px;
  padding-bottom: 6px;
  &:focus {
    outline: none;
  }
`;

export const PageSizeMenuItem = styled.div<{
  isSelected: boolean;
  isHighlighted: boolean;
}>`
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
  padding-left: 10px;
  color: ${({ isSelected, theme }): string =>
    isSelected ? theme.colors.brandBlue : theme.colors.black90Alpha};
  border: solid 1px
    ${({ isHighlighted, isSelected, theme }): string =>
      isHighlighted && !isSelected ? theme.colors.brandBlue : 'transparent;'};
  &:hover {
    color: ${({ theme }): string => theme.colors.brandBlue};
    background-color: ${({ theme }): string => theme.colors.brandBlue05Alpha};
    border: solid 1px transparent;
  }
  &:active {
    color: ${({ theme }): string => theme.colors.brandBlue90Alpha};
    background-color: ${({ theme }): string => theme.colors.brandBlue10Alpha};
  }
`;

export const PageSizeDropdown = styled(ReactSelect)`
  margin-right: 5px;
  display: inline-flex;
  vertical-align: middle;
  & .Select__control {
    font-size: 14px;
    min-height: 24px;
    height: 24px;
    width: 54px;
    box-shadow: none;
    background-color: ${({ theme }): string => theme.colors.white};
    border-radius: 16px;
    &:hover {
      border-radius: 16px;
      border: solid 1px ${({ theme }): string => theme.colors.brandBlue};
      outline: none;
      cursor: pointer;
    }
    &:focus {
      border-radius: 16px;
      border: solid 1px ${({ theme }): string => theme.colors.brandBlue};
      outline: none;
    }
    &:focus-within {
      border-radius: 16px;
      border: solid 1px ${({ theme }): string => theme.colors.brandBlue};
      outline: none;
      .Select__dropdown-indicator {
        transform: rotate(180deg);
      }
    }
    &:active {
      border-radius: 16px;
      border: solid 1px ${({ theme }): string => theme.colors.brandBlue};
    }
  }
  & .Select__input {
    margin: 0px;
  }
  & .Select__value-container {
    height: 24px;
    padding: 0px 6px;
  }
  & .Select__indicator-separator {
    display: none;
  }
  & .Select__indicator {
    height: 24px;
  }
  & .Select__placeholder {
    top: 45%;
  }
  & .Select__option {
    padding: 4px 19px 4px 9px;
    font-size: 14px;
    font-weight: normal;
    color: ${({ theme }): string => theme.colors.black90Alpha};
    width: 52px;
    height: 24px;
    &:hover {
      background-color: ${({ theme }): string => theme.colors.brandBlue05Alpha};
      color: ${({ theme }): string => theme.colors.brandBlue};
      cursor: pointer;
    }
    &:focus {
      border: solid 1px ${({ theme }): string => theme.colors.brandBlue};
      color: ${({ theme }): string => theme.colors.brandBlue};
    }
    &:active {
      color: ${({ theme }): string => theme.colors.brandBlue};
      background-color: ${({ theme }): string => theme.colors.brandBlue10Alpha};
    }
  }
  & .Select__option--is-focused {
    background-color: ${({ theme }): string => theme.colors.brandBlue05Alpha};
    color: ${({ theme }): string => theme.colors.brandBlue};
  }
  & .Select__option--is-selected {
    color: ${({ theme }): string => theme.colors.brandBlue};
    background-color: white;
    :focus {
      outline: none;
    }
  }
  & .Select__menu {
    margin-top: 2px;
    border-radius: 4px;
    border: solid 1px ${({ theme }): string => theme.colors.black10Alpha};
    outline: none;
  }
`;
