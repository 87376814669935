import React, {
  Dispatch,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Portal from '../../utils/Portal';
import { MenuItem } from './ThreeDotMenu';
import {
  Menu,
  MenuItemButton,
  MenuItemLabel,
  MenuItemIcon,
} from './ThreeDotMenuStyles';

type PortalMenuProps = {
  menuItems: MenuItem[];
  parentRef: RefObject<HTMLButtonElement>;
  tableVariant: boolean;
  setMouseIsInsideMenu: Dispatch<React.SetStateAction<boolean>>;
  setIsMenuOpen: Dispatch<React.SetStateAction<boolean>>;
  setActiveItem: Dispatch<React.SetStateAction<number | null | undefined>>;
  activeItem: number | null | undefined;
};

export const PortalMenu: React.FC<PortalMenuProps> = ({
  menuItems,
  parentRef,
  tableVariant,
  setMouseIsInsideMenu,
  setIsMenuOpen,
  activeItem,
  setActiveItem,
}) => {
  const [top, setTop] = useState<number>();
  const [left, setLeft] = useState<number>();
  const [right, setRight] = useState<number>();
  const [isMoreOnTheRight, setIsMoreOnTheRight] = useState<boolean>();
  const calculatePosition = useCallback(() => {
    if (parentRef && parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      setIsMoreOnTheRight(rect.left >= rect.right / 2);
      setTop(rect.top + window.scrollY + rect.height);
      setLeft(rect.left + window.scrollX);
      setRight(rect.right + window.scrollX);
    }
  }, [parentRef]);

  useEffect(() => {
    calculatePosition();
  }, [calculatePosition]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      calculatePosition();
    });
  }, [calculatePosition]);
  return (
    <Portal>
      <Menu
        data-testid="menu"
        top={top}
        left={left}
        right={right}
        isMoreOnTheRight={isMoreOnTheRight}
        tableVariant={tableVariant}
        onMouseEnter={() => {
          setMouseIsInsideMenu(true);
        }}
        onMouseLeave={() => {
          setMouseIsInsideMenu(false);
        }}
      >
        {menuItems.map((menuItem, i) => (
          <MenuItemButton
            isActive={activeItem === i}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setIsMenuOpen(false);
              menuItem.onSelect(event);
            }}
            onMouseLeave={() => {
              setActiveItem(null);
            }}
            onMouseMove={() => {
              if (activeItem !== i) {
                setActiveItem(i);
              }
            }}
            tableVariant={tableVariant}
            destructive={Boolean(menuItem.destructive)}
            data-testid={`menuItemButton-${i}`}
            key={`menuItemButton-${menuItem.label}`}
          >
            <MenuItemLabel data-testid={`menuItemLabel-${i}`}>
              {menuItem.icon && (
                <MenuItemIcon
                  src={menuItem.icon}
                  data-testid={`menuItemIcon-${i}`}
                />
              )}
              {menuItem.label}
            </MenuItemLabel>
          </MenuItemButton>
        ))}
      </Menu>
    </Portal>
  );
};

export default PortalMenu;
