import React, { memo, useCallback } from 'react';
import { Editor } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { colorMatch, isMarkActive, toggleColour } from '../utils';
import { ColorPaletteItem } from './ColorMenu';

interface ColorButtonProps {
  format: string;
  type: string;
}

export default memo(({ format, type }: ColorButtonProps) => {
  const editor: Editor = useSlate();
  const matchedColours = format.match(colorMatch);
  const selectedColour = matchedColours ? matchedColours[0] : '';
  const activeState = isMarkActive(editor, format)
    ? '0 0 0 1px #1990ff'
    : 'none';

  const handleToggleColour = useCallback(
    (event): void => {
      event.preventDefault();
      toggleColour(editor, format, { type });
      ReactEditor.focus(editor);
    },
    [format, editor, type]
  );

  return (
    <ColorPaletteItem
      type="button"
      style={{ backgroundColor: selectedColour, boxShadow: activeState }}
      onMouseDown={handleToggleColour}
    />
  );
});
