import React, { memo, useCallback, useState } from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import { ToolButton, ToolButtonWrapper } from './ToolBarStyles';
import ToolTip from './ToolTip';
import { ensureSelection, isMarkActive, toggleMark } from './utils';

interface MarkButtonProps {
  format: 'bold' | 'italic';
  activeIcon: string;
  icon: string;
}

export default memo(({ format, activeIcon, icon }: MarkButtonProps) => {
  const editor: Editor = useSlate();
  const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);

  const handleToggleMark = useCallback(
    (event) => {
      event.preventDefault();
      ensureSelection(editor);
      toggleMark(editor, format);
    },
    [editor, format]
  );

  return (
    <ToolButtonWrapper>
      <ToolTip text={format} isVisible={tooltipIsVisible}>
        <ToolButton
          onClick={handleToggleMark}
          onMouseEnter={() => {
            setTooltipIsVisible(true);
          }}
          onMouseLeave={() => {
            setTooltipIsVisible(false);
          }}
        >
          <img
            src={isMarkActive(editor, format) ? activeIcon : icon}
            alt="mark button"
          />
        </ToolButton>
      </ToolTip>
    </ToolButtonWrapper>
  );
});
