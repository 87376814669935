import React, { useEffect, useState } from 'react';
import { useSelect } from 'downshift';
import styled from 'styled-components';
import InlineSvg from 'react-inlinesvg';
import dropdownIconSvg from '../../assets/dropdown-icon.svg';

const ComponentWrapper = styled.div`
  width: 100%;
`;

const SelectBox = styled.div<{ itemIsSelected: boolean; menuIsOpen: boolean }>`
  height: 34px;
  font-size: 14px;
  padding-left: 8px;
  display: flex;
  position: relative;
  cursor: pointer;
  background: white;
  border-radius: 2px;
  border: solid 1px
    ${({ menuIsOpen, theme }): string =>
      menuIsOpen ? theme.colors.brandBlue : theme.colors.black10Alpha};
  color: ${({ itemIsSelected, theme }): string =>
    itemIsSelected ? theme.colors.black80Alpha : theme.colors.black30Alpha};
  &:hover {
    border: solid 1px
      ${({ menuIsOpen, theme }): string =>
        menuIsOpen ? theme.colors.brandBlue : theme.colors.brandBlue20Alpha};
  }
  &:focus {
    outline: none;
    border: solid 1px ${({ theme }): string => theme.colors.brandBlue};
  }
`;

const SelectedLabel = styled.div`
  padding-top: 9px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 20px;
`;

const DropdownWrapper = styled.div<{ menuIsOpen: boolean }>`
  width: fit-content;
  position: absolute;
  cursor: pointer;
  left: calc(100% - 23px);
  top: 8px;
  background: transparent;
  border: none;
  ${({ menuIsOpen }): string =>
    menuIsOpen ? 'transform: rotate(180deg); top: 10px;' : ''};
`;

const DropdownIcon = styled(InlineSvg)`
  width: 12px;
  path {
    fill: ${({ theme }): string => theme.colors.black70Alpha};
  }
`;

const MenuOuterWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const MenuWrapper = styled.div<{ isOpen: boolean }>`
  position: absolute;
  background: white;
  max-height: 200px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  width: inherit;
  z-index: 999;
  ${({ isOpen }): string => (!isOpen ? 'display: none' : 'display: block')};
  margin-top: 3px;
  border-radius: 4px;
  border: solid 1px ${({ theme }): string => theme.colors.black10Alpha};
  &:focus {
    outline: none;
  }
  &:first-child {
    padding-top: 4px;
  }
  &:last-child {
    padding-bottom: 4px;
  }
`;

const MenuItem = styled.div<{ isSelected: boolean; isHighlighted: boolean }>`
  font-size: 14px;
  color: ${({ isSelected, theme }): string =>
    isSelected ? theme.colors.brandBlue : theme.colors.black90Alpha};
  cursor: pointer;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 16px;
  box-shadow: ${({ isHighlighted, isSelected, theme }): string =>
    isHighlighted && !isSelected
      ? `inset 0 0 0 1px ${theme.colors.brandBlue}`
      : `none`};
  &:hover {
    box-shadow: none;
    color: ${({ theme }): string => theme.colors.brandBlue};
    background-color: ${({ theme }): string => theme.colors.brandBlue05Alpha};
  }
`;

export type MenuItemDefinition = {
  value: string;
  label: string;
};

export type SelectProps = {
  items: MenuItemDefinition[];
  placeholder?: string;
  onSelect: (item: MenuItemDefinition | null | undefined) => void;
  value?: MenuItemDefinition;
  'data-testid'?: string;
};

export const Select = ({
  items,
  placeholder = 'Please select...',
  onSelect,
  value: initialValue,
  'data-testid': dataTestId = 'Select',
}: SelectProps): JSX.Element => {
  const [value, setValue] = useState<MenuItemDefinition | undefined | null>(
    initialValue
  );
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  const {
    isOpen,
    selectedItem,
    highlightedIndex,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items,
    onSelectedItemChange: (changes) => {
      setValue(changes.selectedItem);
      onSelect(changes.selectedItem);
    },
    circularNavigation: true,
    initialSelectedItem: value,
  });

  return (
    <ComponentWrapper data-testid={dataTestId}>
      <SelectBox
        {...getToggleButtonProps()}
        tabIndex={0}
        itemIsSelected={Boolean(selectedItem)}
        menuIsOpen={isOpen}
        data-testid={`${dataTestId}_SelectBox`}
      >
        <SelectedLabel data-testid={`${dataTestId}_SelectLabel`}>
          {selectedItem ? selectedItem.label : placeholder}
        </SelectedLabel>
        <DropdownWrapper
          menuIsOpen={isOpen}
          data-testid={`${dataTestId}_DropdownWrapper`}
        >
          <DropdownIcon src={dropdownIconSvg} />
        </DropdownWrapper>
      </SelectBox>
      <MenuOuterWrapper>
        <MenuWrapper
          isOpen={isOpen}
          {...getMenuProps()}
          data-testid={`${dataTestId}_MenuWrapper`}
        >
          {isOpen &&
            items.map((item, index) => (
              <MenuItem
                key={`${item.value}`}
                {...getItemProps({ item, index })}
                isSelected={selectedItem?.value === item.value}
                isHighlighted={highlightedIndex === index}
                data-testid={`${dataTestId}_MenuItem-${index}`}
              >
                {item.label}
              </MenuItem>
            ))}
        </MenuWrapper>
      </MenuOuterWrapper>
    </ComponentWrapper>
  );
};

export default Select;
