import React from 'react';
import { MenuItem, ThreeDotMenu } from '../ThreeDotMenu/ThreeDotMenu';
import defaultAvatarSvg from '../../assets/avatar-basic-silver.svg';
import {
  AppTitle,
  ComponentWrapper,
  MenuWrapper,
  NameAndIconWrapper,
  Role,
  TextWrapper,
  TitleIcon,
  UserDetails,
  Username,
  UserIcon,
  Title,
} from './TopNavStyles';
import TopNavLoading from './TopNavLoading';

export type TopNavProps = {
  title: string;
  titleIcon: string;
  username: string;
  userIcon?: string;
  userRole: string;
  menuItems: MenuItem[];
  isLoading?: boolean;
};

export const TopNav: React.FC<TopNavProps> = ({
  menuItems,
  title,
  titleIcon,
  username,
  userIcon,
  userRole,
  isLoading = false,
}) => {
  return (
    <ComponentWrapper data-testid="contentWrapper">
      <AppTitle data-testid="appTitle">
        <TitleIcon src={titleIcon as string} data-testid="titleIcon" />
        <Title>{title}</Title>
      </AppTitle>
      {isLoading ? (
        <TopNavLoading />
      ) : (
        <>
          <UserDetails data-testid="userDetails">
            <NameAndIconWrapper data-testid="nameAndIconWrapper">
              <UserIcon
                src={userIcon || defaultAvatarSvg}
                data-testid="userIcon"
              />
              <TextWrapper data-testid="textWrapper">
                <Username data-testid="username"> {username}</Username>
                <Role data-testid="userRole">{userRole}</Role>
              </TextWrapper>
            </NameAndIconWrapper>
          </UserDetails>
          <MenuWrapper data-testid="menuWrapper">
            <ThreeDotMenu menuItems={menuItems} />
          </MenuWrapper>
        </>
      )}
    </ComponentWrapper>
  );
};

export default TopNav;
