import styled from 'styled-components';

export const ContentsWrapper = styled.div<{ isSticky: boolean }>`
  ${({ isSticky }): string =>
    isSticky ? 'position: fixed;' : 'position: auto;'};
  top: 0;
  right: 0;
  box-shadow: -2px 0 5px 0
    ${({ theme }): string => theme.colors.frostedSteel50Alpha};
  background-color: ${({ theme }): string => theme.colors.snow};
  min-height: 100vh;
  width: 220px;
`;

export const MenuTitle = styled.div`
  width: 200px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.09px;
  color: ${({ theme }): string => theme.colors.black90Alpha};
  background-color: ${({ theme }): string => theme.colors.snow};
  text-transform: uppercase;
  padding-top: var(--spacing-28);
  padding-left: var(--spacing-20);
  padding-bottom: var(--spacing-20);
  padding-right: 0px;
`;

export const SectionTitle = styled.div<{
  isExpanded: boolean;
  subItemSelected: boolean;
}>`
  width: 180px;
  font-size: 14px;
  font-weight: ${({ subItemSelected }): string =>
    subItemSelected ? '600' : '500'};
  letter-spacing: -0.09px;
  color: ${({ isExpanded, subItemSelected, theme }): string =>
    !isExpanded && subItemSelected
      ? theme.colors.brandBlue
      : theme.colors.black70Alpha};
  background-color: ${({ isExpanded, subItemSelected, theme }): string =>
    !isExpanded && subItemSelected
      ? theme.colors.brandBlue15Alpha
      : theme.colors.snow};
  border: solid 1px transparent;
  border-top: solid 2px ${({ theme }): string => theme.colors.white};
  padding: 20px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }): string => theme.colors.brandBlue};
    background-color: ${({ subItemSelected, theme }): string =>
      subItemSelected
        ? theme.colors.brandBlue15Alpha
        : theme.colors.brandBlue05Alpha};
    transition: all 0.4s;
  }
  &:focus {
    outline: none;
    border: solid 1px ${({ theme }): string => theme.colors.brandBlue};
    /* need to put margin to prevent movement due to default having 2px white border on top */
    margin-top: 1px;
  }
  &:active {
    outline: none;
    border: solid 1px transparent;
    border-top: solid 2px ${({ theme }): string => theme.colors.white};
    /* need to put margin to 0 to override the focus states value so it doesn't jiggle */
    margin-top: 0px;
  }
`;

export const ChapterButtonsWrapper = styled.div<{
  itemCount: number;
  isExpanded: boolean;
}>`
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  text-align: left;
  display: flex;
  flex-direction: column;
  border-top: ${({ isExpanded, theme }): string =>
    isExpanded ? `solid 2px ${theme.colors.white}` : 'none;'};
  background-color: ${({ theme }): string => theme.colors.brandBlue02Alpha};
  width: 203px;
  height: ${({ itemCount }): string => {
    const calculatedHeight = itemCount * 35;
    return `${calculatedHeight}px`;
  }};
  ${({ isExpanded }): string =>
    !isExpanded
      ? 'height: 0px; transform: scaleY(0); transition-delay: 0s;'
      : 'padding-top 15px; padding-right: 20px;'};
  max-height: 350px;
  transition-property: height, transform;
  transition-duration: 0.4s, 0.2s;
  &:last-child {
    padding-bottom: var(--spacing-28);
  }
`;

export const ChapterButtonStyle = styled.button<{ isActive: boolean }>`
  border: solid 1px transparent;
  background: transparent;
  text-align: left;
  font-size: 13px;
  font-weight: 500;
  padding-left: 24px;
  padding-top: 4px;
  width: 220px;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.brandBlue : theme.colors.black60Alpha};
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.brandBlue};
    transition: all 0.4s;
  }
  &:focus {
    outline: none;
    border: solid 1px ${({ theme }): string => theme.colors.brandBlue};
  }
  &:active {
    outline: none;
    border: solid 1px transparent;
    color: ${({ theme }): string => theme.colors.brandBlue80Alpha};
  }
  margin-bottom: 10px;
`;

export const OtherContent = styled.div`
  border-top: solid 2px ${({ theme }) => theme.colors.white};
  width: 200px;
  padding-right: 20px;
`;
