import React, { useRef } from 'react';
import { MenuItem } from './SideNav';
import { SubListItem } from './SideNavStyles';

export type SubItemProps = {
  expanded: boolean;
  selected: boolean;
  navIsExpanded: boolean;
  selectItem: (item: MenuItem) => void;
  item: MenuItem;
  subItem: MenuItem;
};

export const SubItem: React.FC<SubItemProps> = ({
  expanded,
  selected,
  navIsExpanded,
  selectItem,
  item,
  subItem,
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const handleSubItemClick = (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
    selectItem(subItem);
  };
  return (
    <SubListItem
      type="button"
      onClick={(event) => {
        handleSubItemClick(event);
        if (ref.current) {
          ref.current.blur();
        }
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleSubItemClick(event);
        }
      }}
      expanded={expanded}
      selected={selected}
      tabIndex={expanded && navIsExpanded ? 0 : -1}
      data-testid={`subItemButton-${item.value}`}
      key={`subListItem-${item.value}-${subItem.value}`}
      ref={ref}
    >
      <span>{subItem.label}</span>
    </SubListItem>
  );
};

export default SubItem;
