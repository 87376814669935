import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  width: 100%;
  height: 70px;
  display: inline-flex;
  background: white;
`;

export const AppTitle = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  margin-left: 0px;
  padding-left: 28px;
`;

export const TitleIcon = styled.img`
  vertical-align: middle;
  width: 24px;
  margin-right: 8px;
`;

export const Title = styled.span`
  line-height: 70px;
  color: ${({ theme }): string => theme.colors.black};
`;

export const UserDetails = styled.div`
  position: absolute;
  height: inherit;
  right: 100px;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  height: 70px;
  right: 100px;
  width: 157px;
`;

export const NameAndIconWrapper = styled.div`
  display: flex;
`;

export const UserIcon = styled.img`
  vertical-align: middle;
  width: 32px;
  height: 32px;
  clip-path: circle(16px at center);
  margin-right: 10px;
  margin-top: 19px;
`;

export const TextWrapper = styled.div`
  padding-top: 16px;
`;

export const Username = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }): string => theme.colors.black};
`;

export const Role = styled.p`
  font-size: 10px;
  margin-top: 2px;
  margin-bottom: 0;
  color: ${({ theme }): string => theme.colors.black40Alpha};
`;

export const MenuWrapper = styled.div`
  margin-top: 24px;
  position: absolute;
  right: 65px;
`;
