import React, { memo, useState } from 'react';
import { ToolButton, ToolButtonWrapper } from './ToolBarStyles';
import ToolTip from './ToolTip';

type BlockButtonProps = {
  activeIcon: string;
  icon: string;
  tooltip: string;
  isActive: boolean;
  onToggle: () => void;
};

export default memo(
  ({ icon, activeIcon, tooltip, isActive, onToggle }: BlockButtonProps) => {
    const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);

    return (
      <ToolButtonWrapper>
        <ToolTip text={tooltip} isVisible={tooltipIsVisible}>
          <ToolButton
            data-testid="BlockButton"
            onClick={onToggle}
            onMouseEnter={() => {
              setTooltipIsVisible(true);
            }}
            onMouseLeave={() => {
              setTooltipIsVisible(false);
            }}
          >
            <img src={isActive ? activeIcon : icon} alt="mark button" />
          </ToolButton>
        </ToolTip>
      </ToolButtonWrapper>
    );
  }
);
