import React, { useCallback } from 'react';
import { Editor } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import ClearColour from '../../../assets/RichTeIcons/icon-highlight-clear.svg';
import { colorIsActive, colors, toggleColour } from '../utils';
import { ColorPaletteItem, RemoveColor } from './ColorMenu';

interface ClearColorButtonProps {
  type: string;
}

export default ({ type }: ClearColorButtonProps): JSX.Element => {
  const editor: Editor = useSlate();

  const aColorIsActive = Object.values(colors[type]).some((color: string) =>
    colorIsActive(editor, `${type}Colour${color}`)
  );

  const activeState = aColorIsActive ? 'none' : '0 0 0 1px #1990ff';

  const handleToggleColour = useCallback(
    (event): void => {
      event.preventDefault();
      toggleColour(editor, 'none', { type });
      ReactEditor.focus(editor);
    },
    [editor, type]
  );

  return (
    <ColorPaletteItem
      type="button"
      onMouseDown={handleToggleColour}
      style={{ boxShadow: activeState }}
    >
      <RemoveColor src={ClearColour} alt="mark button" />
    </ColorPaletteItem>
  );
};
