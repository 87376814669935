import _ from 'lodash';
import { createGlobalStyle, DefaultTheme, css } from 'styled-components';
import toStyle from 'css-to-style';

const sizes = {
  xxxsmall: 400,
  xxsmall: 600,
  iPad: 768,
  xsmall: 780,
  small: 815,
  smallIsh: 1000,
  medium: 1440,
  large: 1600,
};

const colorVariables = css`
  --black-10: rgba(36, 45, 65, 0.1);
  --black-2: rgba(36, 45, 65, 0.02);
  --black-20: rgba(36, 45, 65, 0.2);
  --black-30: rgba(36, 45, 65, 0.3);
  --black-40: rgba(36, 45, 65, 0.4);
  --black-5: rgba(36, 45, 65, 0.05);
  --black-50: rgba(36, 45, 65, 0.5);
  --black-60: rgba(36, 45, 65, 0.6);
  --black-70: rgba(36, 45, 65, 0.7);
  --black-80: rgba(36, 45, 65, 0.8);
  --black-90: rgba(36, 45, 65, 0.9);
  --black: rgb(36, 45, 65);
  --brand-blue-3: rgba(24, 144, 255, 0.03);
  --brand-blue-10: rgba(24, 144, 255, 0.1);
  --brand-blue-15: rgba(24, 144, 255, 0.15);
  --brand-blue-2: rgba(24, 144, 255, 0.02);
  --brand-blue-20: rgba(24, 144, 255, 0.2);
  --brand-blue-30: rgba(24, 144, 255, 0.3);
  --brand-blue-40: rgba(24, 144, 255, 0.4);
  --brand-blue-50: rgba(24, 144, 255, 0.5);
  --brand-blue-60: rgba(24, 144, 255, 0.6);
  --brand-blue-5: rgba(24, 144, 255, 0.05);
  --brand-blue-70: rgba(24, 144, 255, 0.7);
  --brand-blue-80: rgba(24, 144, 255, 0.8);
  --brand-blue-90: rgba(24, 144, 255, 0.9);
  --brand-blue: rgb(24, 144, 255);
  --cobalt: rgb(2, 122, 255);
  --dark-navy: rgb(0, 21, 43);
  --fog: rgb(238, 239, 243);
  --frosted-steel: rgb(219, 227, 237);
  --frosted-steel-10: rgba(219, 227, 237, 0.1);
  --frosted-steel-20: rgba(219, 227, 237, 0.2);
  --frosted-steel-30: rgba(219, 227, 237, 0.3);
  --frosted-steel-40: rgba(219, 227, 237, 0.4);
  --frosted-steel-50: rgba(219, 227, 237, 0.5);
  --frosted-steel-60: rgba(219, 227, 237, 0.6);
  --frosted-steel-70: rgba(219, 227, 237, 0.7);
  --frosted-steel-80: rgba(219, 227, 237, 0.8);
  --frosted-steel-90: rgba(219, 227, 237, 0.9);
  --green: #4aa91c;
  --item-defect: #1e589f;
  --item-llp: #baa3ff;
  --item-mel: #194479;
  --item-oop: #794efc;
  --item-scheduled: #470cfe;
  --jordy: #7ebbef;
  --navy: rgb(0, 30, 61);
  --porcelain: rgb(251, 252, 253);
  --red-10: rgba(202, 35, 34, 0.1);
  --red-5: rgba(202, 35, 34, 0.05);
  --red-90: rgba(202, 35, 34, 0.9);
  --red: rgb(202, 35, 34);
  --salt: rgb(252, 252, 252);
  --scheduled: #470cfe;
  --snow: rgb(250, 251, 253);
  --white-70: rgba(255, 255, 255, 0.7);
  --white-80: rgba(255, 255, 255, 0.8);
  --white: rgb(255, 255, 255);
  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-20: 20px;
  --spacing-24: 24px;
  --spacing-28: 28px;
  --spacing-32: 32px;
  --spacing-40: 40px;
`;

export const GlobalStyle = createGlobalStyle`
  :root {
    --font-family--main: -apple-system,BlinkMacSystemFont,"Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-family: var(--font-family--main);
    ${colorVariables}
  }
  body {
    font-family: var(--font-family--main);
    margin: 0px;
  }
`;

interface ExtendedDefaultTheme extends DefaultTheme {
  media: Record<string, string>
};

export const trustFlight: ExtendedDefaultTheme = {
  colors: {
    black: 'var(--black)',
    black02Alpha: 'var(--black-2)',
    black05Alpha: 'var(--black-5)',
    black10Alpha: 'var(--black-10)',
    black20Alpha: 'var(--black-20)',
    black30Alpha: 'var(--black-30)',
    black40Alpha: 'var(--black-40)',
    black50Alpha: 'var(--black-50)',
    black60Alpha: 'var(--black-60)',
    black70Alpha: 'var(--black-70)',
    black80Alpha: 'var(--black-80)',
    black90Alpha: 'var(--black-90)',
    brandBlue: 'var(--brand-blue)',
    brandBlue02Alpha: 'var(--brand-blue-2)',
    brandBlue03Alpha: 'var(--brand-blue-3)',
    brandBlue05Alpha: 'var(--brand-blue-5)',
    brandBlue10Alpha: 'var(--brand-blue-10)',
    brandBlue15Alpha: 'var(--brand-blue-15)',
    brandBlue20Alpha: 'var(--brand-blue-20)',
    brandBlue30Alpha: 'var(--brand-blue-30)',
    brandBlue40Alpha: 'var(--brand-blue-40)',
    brandBlue50Alpha: 'var(--brand-blue-50)',
    brandBlue60Alpha: 'var(--brand-blue-60)',
    brandBlue70Alpha: 'var(--brand-blue-70)',
    brandBlue80Alpha: 'var(--brand-blue-80)',
    brandBlue90Alpha: 'var(--brand-blue-90)',
    cadetBlueFaded: 'hsla(216,19%, 74%, 50%)',
    cobalt: 'var(--cobalt)',
    fog: 'var(--fog)',
    frostedSteel: 'var(--frosted-steel)',
    frostedSteel10Alpha: 'var(--frosted-steel-10)',
    frostedSteel20Alpha: 'var(--frosted-steel-20)',
    frostedSteel30Alpha: 'var(--frosted-steel-30)',
    frostedSteel40Alpha: 'var(--frosted-steel-40)',
    frostedSteel50Alpha: 'var(--frosted-steel-50)',
    frostedSteel60Alpha: 'var(--frosted-steel-60)',
    frostedSteel70Alpha: 'var(--frosted-steel-70)',
    frostedSteel80Alpha: 'var(--frosted-steel-80)',
    frostedSteel90Alpha: 'var(--frosted-steel-90)',
    itemMel: 'var(--item-mel)',
    navy: 'var(--navy)',
    navyDark: 'var(--dark-navy)',
    porcelain: 'var(--porcelain)',
    red: 'var(--red)',
    red10Alpha: 'var(--red-10)',
    red5Alpha: 'var(--red-5)',
    red90Alpha: 'var(--red-90)',
    salt: 'var(--salt)',
    snow: 'var(--snow)',
    white: 'var(--white)',
    white70Alpha: 'var(--white-70)',
    white80Alpha: 'var(--white-80)',
    green: '#4AA91C',
  },
  gradients: {
    tfGradientGreen: 'linear-gradient(180deg, #7bc88d 0%, #4aa91c 100%)',
    tfGradientAmber: 'linear-gradient(180deg, #fad288 0%, #f2a650 100%)',
    tfGradientRed: 'linear-gradient(180deg, #ff7474 0%, #ff4040 100%)',
    tfGradientGrey: 'linear-gradient(0deg, #d2d2d2 0%, #e0e0e0 100%)',
    tfGradientBlue: 'linear-gradient(201deg, #81bfff 16%, #57aaff 73%)',
  },
  font: {
    fontFamily: 'var(--font-family--main)',
  },
  media: {
    xxxsmallOnly: `@media screen and (max-width: ${sizes.xxxsmall - 1}px)`,
    xxsmallUp: `@media screen and (min-width: ${sizes.xxsmall}px)`,
    xxsmallOnly: `@media screen and (max-width: ${sizes.xxsmall - 1}px)`,
    xsmallUp: `@media screen and (min-width: ${sizes.xsmall}px)`,
    xsmallOnly: `@media screen and (max-width: ${sizes.xsmall - 1}px)`,
    smallUp: `@media screen and (min-width: ${sizes.small}px)`,
    smallOnly: `@media screen and (max-width: ${sizes.small - 1}px)`,
    iPadUp: `@media screen and (min-width: ${sizes.iPad}px)`,
    iPadUpOnly: `@media screen and (max-width: ${sizes.iPad - 1}px)`,
    smallIshUp: `@media screen and (min-width: ${sizes.smallIsh}px)`,
    mediumUp: `@media screen and (min-width: ${sizes.medium}px)`,
    largeUp: `@media screen and (min-width: ${sizes.large}px)`,
  },
};

export const testTheme = _.cloneDeep(trustFlight);
const varToColorMap: Record<string, string> = toStyle(colorVariables[0]);
const newColors: Record<string, string> = {};
_.forEach(testTheme.colors, (value, key) => {
  const colorCssVar = (value as unknown) as string;
  newColors[key] = varToColorMap[colorCssVar.substring(4, colorCssVar.length - 1)];
});
testTheme.colors = newColors;

