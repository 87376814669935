import styled from 'styled-components';
import InlineSvg from 'react-inlinesvg';
import media from '../../styles/mediaQueries';

type NavProps = {
  expanded: boolean;
};

export const ComponentWrapper = styled.div`
  width: 100vw;
  z-index: 10;
  ${media.smallUp} {
    display: inline-flex;
  }
`;

type ContentWrapperProps = {
  expanded: boolean | undefined;
};

export const ContentWrapper = styled.div<ContentWrapperProps>`
  width: 100%;
  overflow: ${({ expanded }): string => (expanded ? 'hidden;' : 'auto')};
  touch-action: ${({ expanded }): string => (expanded ? 'none;' : 'auto')};
  position: ${({ expanded }): string => (expanded ? 'fixed' : 'auto')};
  // overflow: hidden;
  // touch-action: none;
  // position: fixed;
`;

export const NavWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  z-index: 100;
  ${media.smallUp} {
    height: 100vh;
  }
`;

const NavElement = styled.div<NavProps>`
  color: ${({ theme }): string => theme.colors.white70Alpha};
  width: ${({ expanded }): string => (expanded ? '180px;' : '60px')};
  transition: width 0.3s;
  background: ${({ theme }): string => theme.colors.navyDark};
`;

export const Nav = styled(NavElement)`
  display: block;
  flex-flow: column nowrap;
  overflow: hidden;
  ${media.iPadUp} {
    height: calc(100vh - 140px);
  }
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  display: block;
  flex-flow: column nowrap;
  overflow: hidden;
  ${media.smallOnly} {
    width: 100vw;
    height: ${({ expanded }): string => (expanded ? '100vh' : '10px')};
    display: block;
    flex-flow: column nowrap;
    overflow: hidden;
  }
`;

export const LogoWrapper = styled(NavElement)`
  ${media.iPadUp} {
    width: ${({ expanded }): string => (expanded ? '150px;' : '30px')};
    transition: width 0.3s;
  }
  height: 60px;
  padding: 30px 15px 0;
  ${media.smallOnly} {
    height: 45px;
    padding: 20px 15px 0;
    width: calc(100vw - 30px);
  }
  display: flex;
`;
export const TrustflightLogo = styled.img<NavProps>`
  transition: height 0.2s;
  ${media.smallOnly} {
    height: 26px;
  }
  ${media.iPadUp} {
    height: ${({ expanded }): string => (expanded ? '26px;' : '30px')};
  }
`;

export const TrustflightText = styled.img<NavProps>`
  height: 23px;
  margin-top: 3px;
  margin-left: 14px;
  ${media.iPadUp} {
    display: ${({ expanded }): string => (expanded ? 'inherit;' : 'none;')};
  }
`;

export const List = styled.div<NavProps>`
  margin: 0;
  padding: 0;
  ${media.smallOnly} {
    height: ${({ expanded }): string => (expanded ? '100vh;' : '0px;')};
    opacity: ${({ expanded }): string => (expanded ? '1;' : '0;')};
    transition: opacity 0.4s;
  }
`;

export const ListItem = styled.div`
  margin: 0;
  ${media.smallOnly} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

type SubListProps = {
  expanded: boolean;
  itemCount: number;
};

export const SubList = styled.div<SubListProps>`
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: ${({ itemCount }): string => {
    const calculatedHeight = itemCount * 40;
    return `${calculatedHeight}px`;
  }};
  ${({ expanded }): string =>
    !expanded
      ? 'height: 0px; transform: scaleY(0); transition-delay: 0s;'
      : ''};
  transition-property: height, transform;
  transition-duration: 0.4s, 0.2s;
  transition-delay: 0s, 0.1s;
`;

type ItemButtonProps = {
  selected: boolean;
  expanded: boolean;
  navIsExpanded: boolean;
  subitemselected: boolean;
};

const BaseButton = styled.button`
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  text-decoration: none;
  white-space: nowrap;
  outline: none;
  transition: background-color 0.4s;
  border: solid 1px transparent;
  padding: 0;
  color: ${({ theme }): string => theme.colors.white70Alpha};
  font-size: 14px;
  letter-spacing: 0.52px;
  &:hover {
    cursor: pointer;
    background: ${({ theme }): string => theme.colors.brandBlue80Alpha};
  }
  &:focus {
    border: solid 1px var(--jordy);
  }
  &:active {
    opacity: 0.7;
    border: solid 1px transparent;
    color: ${({ theme }): string => theme.colors.white};
  }
`;

export const ItemButton = styled(BaseButton)<ItemButtonProps>`
  background: ${({
    theme,
    selected,
    subitemselected,
    expanded,
    navIsExpanded,
  }): string => {
    if (
      selected ||
      (subitemselected && !navIsExpanded) ||
      (subitemselected && !expanded)
    ) {
      return theme.colors.brandBlue;
    }
    if (expanded && !navIsExpanded) {
      return theme.colors.brandBlue70Alpha;
    }
    return theme.colors.navyDark;
  }};
  ${media.smallOnly} {
    height: 64px;
    border-bottom: solid 1px ${({ theme }): string => theme.colors.black80Alpha};
  }
`;

export const ItemIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 21px;
  margin-right: 10px;
`;

export const ItemChevron = styled(InlineSvg)<{
  expanded: boolean;
  subitemselected: boolean;
}>`
  width: 8px;
  height: 8px;
  margin-right: 24px;
  margin-left: auto;
  transition: transform 0.4s;
  fill: white;
  transform: ${({ expanded }): string =>
    expanded ? 'rotate(-90deg);' : 'rotate(90deg);'};
  ${media.smallOnly} {
    width: 16px;
    height: 16px;
    fill: ${({ expanded, subitemselected, theme }): string =>
      expanded || subitemselected
        ? theme.colors.white
        : theme.colors.brandBlue};
    opacity: 1;
  }
`;

type SubNavItemProps = {
  selected: boolean;
  expanded: boolean;
};

export const SubListItem = styled(BaseButton)<SubNavItemProps>`
  height: 40px;
  transition-property: height, background-color, opacity;
  transition-duration: 0s, 0.4s, 0.4s;
  transition-delay: 0s, 0s, 0s;

  color: ${({ selected, theme }): string =>
    selected ? theme.colors.white : theme.colors.white70Alpha};
  background: ${({ selected, theme }): string =>
    selected ? theme.colors.brandBlue : theme.colors.navyDark};
  padding-left: 49px; /* instead of icon */
  &:hover {
    cursor: pointer;
    background: ${({ theme }): string => theme.colors.brandBlue80Alpha};
    color: ${({ theme }): string => theme.colors.white80Alpha};
  }
  &:active {
    background: ${({ theme }): string => theme.colors.brandBlue70Alpha};
    color: ${({ theme }): string => theme.colors.white70Alpha};
    border: solid 1px transparent;
  }
  ${media.smallOnly} {
    &:hover {
      background: ${({ theme }): string => theme.colors.navyDark};
    }
    &:focus {
      box-shadow: none;
    }
    background: ${({ theme }): string => theme.colors.navyDark};
    color: ${({ selected, theme }): string =>
      selected ? theme.colors.brandBlue : 'white;'};
  }
`;

export const ExpandWrapper = styled.div<NavProps>`
  background: ${({ theme }): string => theme.colors.navyDark};
  height: 50px;
  width: ${({ expanded }): string => (expanded ? '180px;' : '60px')};
  transition: width 0.3s;
  ${media.smallOnly} {
    display: none;
  }
`;

export const ExpandButton = styled.button`
  position: absolute;
  bottom: 0;
  width: 60px;
  height: 50px;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  outline: none;
`;

export const ExpandIcon = styled.img<NavProps>`
  width: 18px;
  height: 18px;
  transition: transform 0.2s;
  transform: ${({ expanded }): string =>
    expanded ? 'scaleX(-1);' : 'translateX(5px);'};
  &:hover {
    transform: ${({ expanded }): string =>
      expanded ? 'scaleX(-1); translateX(5px);' : ''};
  }
`;

export const MobileExpandWrapper = styled.div`
  width: 100%;
  padding-top: 5px;
  z-index: 1000;
  ${media.smallUp} {
    display: none;
  }
`;

export const MobileExpandButton = styled.button`
  float: right;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  outline: none;
`;

export const MobileExpandIcon = styled.img<NavProps>`
  width: ${({ expanded }): string => (expanded ? '16px;' : '24px;')};
`;

export const CollapsedSubListWrapper = styled.div<NavProps>`
  ${media.smallOnly} {
    display: none;
  }
  position: absolute;
  margin-left: 65px;
`;

export const CollapsedSubList = styled.div`
  position: relative;
  top: -49px;
  border-radius: 2px;
  background: ${({ theme }): string => theme.colors.navyDark};
`;

export const CollapsedSubListItem = styled(SubListItem)`
  padding-left: 15px;
  padding-right: 15px;
`;

export const CollapsedTooltipWrapper = styled.div`
  position: absolute;
`;

export const CollapsedTooltip = styled.div`
  margin-left: 65px;
  position: relative;
  top: -35px;
  background: ${({ theme }): string => theme.colors.navyDark};
  font-size: 12px;
  border-radius: 2px;
  padding: 5px;
`;
