/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import styled from 'styled-components';
import LoadingStart from './loadingStart.json';
import LoadingLoop from './loadingLoop.json';

const Wrapper = styled.div`
  background: transparent;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const Animation = styled(Player)<{ size: number }>`
  height: 95px;
  width: 100px;
  padding-bottom: 5px;
`;

type LoadingAnimationProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  play?: boolean;
};

export const LoadingAnimation: React.FC<LoadingAnimationProps> = () => {
  const [displayLoop, setDisplayLoop] = useState<boolean>(false);
  return (
    <Wrapper data-testid="LoadingAnimation">
      <LottieWrapper>
        {!displayLoop ? (
          <Animation
            src={LoadingStart}
            autoplay
            onEvent={(event: string) => {
              if (event === 'pause') {
                setDisplayLoop(true);
              }
            }}
          />
        ) : (
          <Animation src={LoadingLoop} autoplay loop />
        )}
      </LottieWrapper>
    </Wrapper>
  );
};

export default LoadingAnimation;
