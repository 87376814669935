import { FilterGroup, FilterDefinition } from './FilterSideBar';

type FilterFactoryProps<T> = {
  data: T[];
  filterStates: FilterDefinition<T>[];
  filterGroups: FilterGroup<T>[];
};

export interface Filter<T> {
  data: T[];
  filterStates: FilterDefinition<T>[];
  filterGroups: FilterGroup<T>[];
  unionOfFilteredResults: T[];
  setOfResults: Set<T>;
  calculateIntersection: (nextFilterSet: T[]) => void;
  produceResults: () => void;
  getSetOfResults: () => T[];
  haveSetOfResults: () => boolean;
}

const filterFactory = <T,>({
  data = [],
  filterStates = [],
  filterGroups = [],
}: FilterFactoryProps<T>): Filter<T> => ({
  data,
  filterStates,
  filterGroups,
  unionOfFilteredResults: [],
  setOfResults: new Set(),

  calculateIntersection(nextFilterSet) {
    if (this.setOfResults.size === 0) {
      this.setOfResults = new Set<T>(nextFilterSet);
      return;
    }

    const intersection = new Set<T>();
    for (const elem of nextFilterSet) {
      if (this.setOfResults.has(elem)) {
        intersection.add(elem);
      }
    }
    this.setOfResults = intersection;
  },

  produceResults() {
    this.filterGroups.forEach((filterGroup) => {
      this.unionOfFilteredResults = [];

      filterGroup.filters.forEach((filter) => {
        if (
          this.filterStates.filter((item) => {
            return (
              `${item.key}/${item.value}` === `${filter.key}/${filter.value}`
            );
          }).length
        ) {
          this.unionOfFilteredResults = this.unionOfFilteredResults.concat(
            this.data.filter((item) => filter.filterFunction(item))
          );
        }
      });

      if (this.unionOfFilteredResults.length > 0) {
        this.calculateIntersection(this.unionOfFilteredResults);
      }
    });
  },

  getSetOfResults() {
    return Array.from(this.setOfResults);
  },

  haveSetOfResults() {
    return this.setOfResults.size > 0;
  },
});

export default filterFactory;
