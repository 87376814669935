import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SortingArrowSvg from '../../assets/icon-sort.svg';

const Wrapper = styled.div``;

const NumberInput = styled.input`
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  text-align: right;
  line-height: normal;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 100%;
  height: 34px;
  border: 1px solid ${({ theme }): string => theme.colors.black10Alpha};
  margin-right: 5px;
  padding-right: 30px;
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }): string => theme.colors.brandBlue};
  }
`;

const NumberInputWrapper = styled.div`
  display: flex;
  &:hover {
    ${NumberInput} {
      :not(:focus) {
        border: 1px solid
          ${({ theme }): string => theme.colors.brandBlue20Alpha};
      }
    }
  }
`;
const ButtonsOuterWrapper = styled.div`
  position: relative;
`;

const CounterButtonWrapper = styled.div`
  display: grid;
  position: absolute;
  right: 5px;
`;
const CounterButton = styled.button<{ bottomDivider?: true }>`
  background: transparent;
  border: 1px solid ${({ theme }): string => theme.colors.black10Alpha};
  border-top: none;
  border-right: none;
  height: 18px;
  border-bottom: ${({ bottomDivider, theme }): string =>
    bottomDivider ? `border: 1px solid ${theme.colors.black10Alpha}` : 'none;'};
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`;
const CounterIcon = styled.img<{ isDown?: boolean }>`
  width: 8px;
  color: ${({ theme }): string => theme.colors.brandBlue};
  ${({ isDown }): string => (isDown ? 'transform: rotate(180deg);' : '')};
`;

export interface NumberStepperProps {
  count?: number;
  min?: number;
  max?: number;
  onChange?: (value: number) => void;
  onBlur?: (value: number) => void;
  'data-testid'?: string;
}

export const NumberStepper: React.FC<NumberStepperProps> = ({
  count = 0,
  min,
  max,
  onBlur,
  onChange,
  'data-testid': dataTestId = 'NumberStepper',
}) => {
  const [value, setValue] = useState<number>(count);
  useEffect(() => {
    setValue(count);
  }, [count]);
  return (
    <Wrapper data-testid={dataTestId}>
      <NumberInputWrapper>
        <NumberInput
          type="number"
          value={value}
          min={min}
          onChange={(event) => {
            setValue(parseInt(event.target.value, 10));
            if (onChange) {
              onChange(parseInt(event.target.value, 10));
            }
          }}
          onBlur={(event) => {
            if (onBlur) {
              onBlur(parseInt(event.target.value, 10));
            }
          }}
          data-testid={`${dataTestId}_Input`}
        />
        <ButtonsOuterWrapper>
          <CounterButtonWrapper>
            <CounterButton
              type="button"
              onClick={(): void => {
                if ((!max && max !== 0) || value < max) {
                  setValue(value + 1);
                  if (onChange) {
                    onChange(value + 1);
                  }
                }
              }}
              bottomDivider
              data-testid={`${dataTestId}_UpButton`}
            >
              <CounterIcon src={SortingArrowSvg} />
            </CounterButton>
            <CounterButton
              type="button"
              onClick={(): void => {
                if ((!min && min !== 0) || value > min) {
                  setValue(value - 1);
                  if (onChange) {
                    onChange(value - 1);
                  }
                }
              }}
              data-testid={`${dataTestId}_DownButton`}
            >
              <CounterIcon src={SortingArrowSvg} isDown />
            </CounterButton>
          </CounterButtonWrapper>
        </ButtonsOuterWrapper>
      </NumberInputWrapper>
    </Wrapper>
  );
};

export default NumberStepper;
