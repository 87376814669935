import React, { memo, useCallback, useState } from 'react';
import { useSlate } from 'slate-react';
import { Editor, Transforms } from 'slate';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';
import {
  colorIcons,
  ToolButton,
  ToolButtonWrapper,
  toolBarColours,
} from '../ToolBarStyles';
import ToolTip from '../ToolTip';
import { colorIsActive } from '../utils';

const ColorMenuOuterWrapper = styled.div`
  position: relative;
`;

export const ColorMenus = styled.div`
  display: flex;
  position: absolute;
  top: 15px;
  left: -40px;
  width: 172px;
  height: 81px;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 ${({ theme }) => theme.colors.black10Alpha};
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 5;
`;

export const ColorPaletteItem = styled.button`
  margin: 3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: relative;
  cursor: pointer;
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.brandBlue10Alpha};
  }
`;

export const RemoveColor = styled.img`
  width: 16px;
  height: 16px;
  top: -2px;
  left: -2px;
  position: absolute;
`;

export const TextColorPopupTopRow = styled.div`
  display: flex;
  height: 40px;
  padding: 5px;
`;

export const TextColorPopupBottomRow = styled.div`
  display: flex;
  padding-left: 8px;
`;

export const TextColorPicker = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColorPaletteLabel = styled.p`
  height: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 2px;
`;

interface ColorMenuProps {
  children: JSX.Element | JSX.Element[];
  showMenu: boolean;
  menuToggle(fn: (menuState: boolean) => boolean): void;
  toggleOtherMenus: {
    siblingColourMenu: (arg0: boolean) => void;
    tableMenu: (arg0: boolean) => void;
  };
  otherMenuStates: boolean[];
  icon: string;
  label: string;
  type: string;
}

interface CurrentActiveColourProps {
  type: string;
  editor: Editor;
  icon: string;
}

const CurrentActiveColour: React.FC<CurrentActiveColourProps> = ({
  type,
  editor,
  icon,
}) => {
  let currentColour = <img src={icon} alt="mark button" />;

  Object.values(toolBarColours[type]).forEach((color: string) => {
    if (colorIsActive(editor, `${type}Colour${color}`)) {
      currentColour = colorIcons[color];
    }
  });

  return currentColour;
};

export default memo(
  ({
    children,
    showMenu,
    menuToggle,
    icon,
    label,
    type,
    toggleOtherMenus: { siblingColourMenu, tableMenu },
    otherMenuStates,
  }: ColorMenuProps) => {
    const editor: Editor = useSlate();
    const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);

    const handleMenuToggle = useCallback(() => {
      if (otherMenuStates.includes(true)) {
        siblingColourMenu(false);
        tableMenu(false);
      }
      if (!editor.selection) {
        Transforms.select(editor, {
          anchor: { path: [0, 0], offset: 0 },
          focus: { path: [0, 0], offset: 0 },
        });
      }
      menuToggle((menuState) => !menuState);
    }, [otherMenuStates, editor, menuToggle, siblingColourMenu, tableMenu]);

    return (
      <>
        <ToolButtonWrapper>
          <ToolTip
            text={`${type}-color`}
            isVisible={tooltipIsVisible && !showMenu}
          >
            <ToolButton
              type="button"
              onClick={handleMenuToggle}
              onMouseEnter={() => {
                setTooltipIsVisible(true);
              }}
              onMouseLeave={() => {
                setTooltipIsVisible(false);
              }}
            >
              <CurrentActiveColour type={type} editor={editor} icon={icon} />
            </ToolButton>
          </ToolTip>
        </ToolButtonWrapper>
        {showMenu && (
          <OutsideClickHandler
            onOutsideClick={() => {
              handleMenuToggle();
            }}
          >
            <ColorMenuOuterWrapper>
              <ColorMenus>
                <TextColorPopupTopRow>
                  <TextColorPicker>
                    <img src={icon} alt="icon" />
                  </TextColorPicker>
                  <ColorPaletteLabel>{label}</ColorPaletteLabel>
                </TextColorPopupTopRow>
                <TextColorPopupBottomRow onClick={handleMenuToggle}>
                  {children}
                </TextColorPopupBottomRow>
              </ColorMenus>
            </ColorMenuOuterWrapper>
          </OutsideClickHandler>
        )}
      </>
    );
  }
);
