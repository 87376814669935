import React, { memo, useCallback, useState } from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';
import SanitiseActive from '../../assets/RichTeIcons/sanitise-active.svg';
import SanitiseInactive from '../../assets/RichTeIcons/sanitise-inactive.svg';
import { ToolButton, ToolButtonWrapper } from './ToolBarStyles';
import ToolTip from './ToolTip';

interface SanitiseProps {
  name: string;
}

export default memo(({ name }: SanitiseProps) => {
  const editor: Editor = useSlate();
  const [sanitiseState, updateSanitiseState] = useState(false);
  const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);

  const target = useCallback(() => document.querySelector(`#slate-${name}`), [
    name,
  ]);

  const applyRegexOnPaste = useCallback(
    (event): void => {
      event.preventDefault();
      let paste = event.clipboardData.getData('text');

      // Replace carriage returns with spaces
      paste = paste.replace(/(\\n|\\r)/gm, ' ');
      // Replace multiple spaces, then trim to remove leading/trailing spaces
      paste = paste.replace(/\s+/gm, ' ').trim();
      // Find space-single_char-bracket-space combos; replace the first space with a newline
      paste = paste.replace(/\s([a-z]{1}\)\s)/gm, '\n$1');
      // replace spaces after NOTE: with newline
      paste = paste.replace(/(NOTE:|NOTES:|WARNING:|CAUTION:)/gm, '\n\n$1');

      try {
        Editor.insertText(editor, paste);
      } catch (e) {
        console.error(e);
      }
    },
    [editor]
  );

  const toggleSanitise = (): void => {
    if (sanitiseState === false) {
      target().addEventListener('paste', applyRegexOnPaste);
    } else {
      target().removeEventListener('paste', applyRegexOnPaste);
    }
    updateSanitiseState(!sanitiseState);
  };

  return (
    <>
      {sanitiseState ? (
        <ToolButtonWrapper>
          <ToolTip text="sanitise" isVisible={tooltipIsVisible}>
            <ToolButton
              type="button"
              onClick={toggleSanitise}
              onMouseEnter={() => {
                setTooltipIsVisible(true);
              }}
              onMouseLeave={() => {
                setTooltipIsVisible(false);
              }}
            >
              <img src={SanitiseActive} alt="mark button" />
            </ToolButton>
          </ToolTip>
        </ToolButtonWrapper>
      ) : (
        <ToolButtonWrapper>
          <ToolTip text="sanitise" isVisible={tooltipIsVisible}>
            <ToolButton
              type="button"
              onClick={toggleSanitise}
              onMouseEnter={() => {
                setTooltipIsVisible(true);
              }}
              onMouseLeave={() => {
                setTooltipIsVisible(false);
              }}
            >
              <img src={SanitiseInactive} alt="mark button" />
            </ToolButton>
          </ToolTip>
        </ToolButtonWrapper>
      )}
    </>
  );
});
