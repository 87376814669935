/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import Badge from '../Badge/Badge';
import IconRemoveSvg from '../../assets/icon-close.svg';
import { FilterDefinition } from './FilterSideBar';

const FilterRow = styled.div`
  margin: 0.2rem 0;
  display: flex;
  align-items: center;
  width: 100%;
`;

type FilterStyledProps = {
  checked: boolean;
  count?: number;
};

const Label = styled.span<FilterStyledProps>`
  color: ${({ checked }) => (checked ? 'white;' : 'inherit;')};
  font-weight: normal;
  font-size: 14px;
`;

const Count = styled.span`
  margin-left: 1px;
`;

const RemoveIcon = styled.img`
  height: 8px;
  position: absolute;
  margin-left: -12px;
`;

const LabelWrapper = styled.label<FilterStyledProps>`
  display: flex;
  align-items: center;
  padding: 0.1rem 0.5rem;
  padding-left: 26px;
  border-radius: 1.5rem;
  width: 180px;
  svg {
    flex-shrink: 0;
  }
  color: ${({ count, theme }) =>
    !count ? theme.colors.black30Alpha : theme.colors.black70Alpha};
  ${({ checked, theme }) =>
    checked
      ? ` background: ${theme.colors.brandBlue};
  color: white;`
      : null}
  transition: all 400ms;
  &:hover {
    ${({ count, checked, theme }) =>
      count || checked
        ? `cursor:pointer;
          color: white;
          background: ${theme.colors.brandBlue70Alpha};`
        : null};
  }
`;

type FilterButtonProps<T> = {
  filter: FilterDefinition<T>;
  updateFilterState: (options: {
    filter: FilterDefinition<T>;
    filterState: boolean;
  }) => void;
  filterStates: FilterDefinition<T>[];
  count: number;
};

const FilterButton = <T,>({
  filter,
  updateFilterState,
  filterStates,
  count,
}: FilterButtonProps<T>): JSX.Element => {
  const { label, badge } = filter;
  const checked = filterStates.includes(filter);

  const handleUpdateFilterState = () => {
    if (count > 0 || checked) {
      updateFilterState({ filterState: !checked, filter });
    }
  };

  return (
    <FilterRow data-testid="filterRow" onClick={handleUpdateFilterState}>
      <LabelWrapper
        htmlFor={`${label}-filter`}
        checked={checked}
        count={count}
        data-testid="labelWrapper"
      >
        {checked && <RemoveIcon src={IconRemoveSvg} />}
        {badge && <Badge color={badge} />}
        <Label checked={checked} data-testid="label">
          {label} <Count data-testid="count">({count})</Count>
        </Label>
      </LabelWrapper>
    </FilterRow>
  );
};

export default FilterButton;
