import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { DropAnimation } from './DropAnimation';
import HoverAnimation from './HoverAnimation';
import { LoadingAnimation } from './LoadingAnimation';
import UnsupportedAnimation from './UnsupportedAnimation';

export type DropzoneProps = {
  onDrop: (acceptedFiles: File[]) => void;
  accept?: string;
  'data-testid'?: string;
};

const Icon = styled.img<{ fileHasBeenDraggedIn: boolean }>`
  ${({ fileHasBeenDraggedIn }): string =>
    fileHasBeenDraggedIn ? 'transform: rotate(180deg)' : ''};
  margin-top: 15px;
  height: 80px;
`;

const Box = styled.div<{ fileIsUploading: boolean }>`
  height: 160px;
  width: 230px;
  border: dashed 1px ${({ theme }): string => theme.colors.brandBlue};
  text-align: center;

  &:hover {
    ${({ fileIsUploading }): string =>
      fileIsUploading ? 'cursor: wait;' : 'cursor: pointer;'};
    ${Icon} {
      ${({ fileIsUploading }): string =>
        !fileIsUploading ? 'height: 85px; margin-top: 10px;' : ''};
    }
  }
`;

const FileInput = styled.input``;

const FileTypes = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }): string => theme.colors.black90Alpha};
`;

const Instructions = styled.div`
  font-size: 12px;
  color: ${({ theme }): string => theme.colors.black50Alpha};
  padding-left: 20px;
  padding-right: 20px;
`;

const Browser = styled.span`
  color: ${({ theme }): string => theme.colors.brandBlue};
`;

export const Dropzone: React.FC<DropzoneProps> = ({
  onDrop: handleDrop,
  accept = 'image/jpeg, .png, pdf',
  'data-testid': dataTestId = 'Dropzone',
}) => {
  const [fileHasBeenDraggedIn, setFileHasBeenDraggedIn] = useState<boolean>(
    false
  );
  const [uploadingFileName, setUploadingFileName] = useState<string | null>(
    null
  );
  const validFileTypes = accept
    .split(',')
    .map((acceptedType) => {
      const trimmedType = acceptedType.trim();
      if (trimmedType.includes('.')) {
        // just assume it's a .* ext provided
        return trimmedType.toUpperCase();
      }
      if (trimmedType.includes('/')) {
        // assuming it's a mimetype like image/jpeg
        return `.${trimmedType.split('/')[1].toUpperCase()}`;
      }
      // default to just adding a period assuming it's an arbitrary ext
      return `.${trimmedType.toUpperCase()}`;
    })
    .join(' ');

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setFileHasBeenDraggedIn(false); // reset flag for animation
      setUploadingFileName(acceptedFiles[0].name);
      await handleDrop(acceptedFiles);
      setUploadingFileName(null);
    },
    [handleDrop]
  );

  const onDragEnter = useCallback(() => {
    if (!fileHasBeenDraggedIn) {
      setFileHasBeenDraggedIn(true);
    }
  }, [fileHasBeenDraggedIn]);

  const onDragLeave = useCallback(() => {
    if (fileHasBeenDraggedIn) {
      setFileHasBeenDraggedIn(false);
    }
  }, [fileHasBeenDraggedIn]);

  const [invalidFileDropped, setInvalidFileDropped] = useState<boolean>(false);
  const onDropRejected = useCallback(() => {
    setFileHasBeenDraggedIn(false);
    setInvalidFileDropped(true);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDropAccepted: onDrop,
    onDragEnter,
    onDragLeave,
    onDropRejected,
    maxFiles: 1,
  });
  const [playHoverAnimation, setPlayHoverAnimation] = useState<boolean>(false);
  return (
    <>
      <Box
        {...getRootProps()}
        fileIsUploading={Boolean(uploadingFileName)}
        data-testid={dataTestId}
        onMouseEnter={() => {
          setPlayHoverAnimation(true);
        }}
        onMouseLeave={() => {
          setPlayHoverAnimation(false);
        }}
      >
        {uploadingFileName ? (
          <>
            <LoadingAnimation />
            <Instructions data-testid={`${dataTestId}_CurrentlyUploadingText`}>
              Uploading {uploadingFileName}
            </Instructions>
          </>
        ) : (
          <>
            <FileInput
              {...getInputProps()}
              data-testid={`${dataTestId}_FileInput`}
            />
            {fileHasBeenDraggedIn && <DropAnimation />}
            {!fileHasBeenDraggedIn && !invalidFileDropped && (
              <HoverAnimation play={playHoverAnimation} />
            )}
            {invalidFileDropped && !fileHasBeenDraggedIn && (
              <UnsupportedAnimation
                onAnimationEnd={() => {
                  setInvalidFileDropped(false);
                }}
              />
            )}

            <FileTypes data-testid={`${dataTestId}_FileTypes`}>
              {validFileTypes}
            </FileTypes>
            <Instructions data-testid={`${dataTestId}_UploadInstruction`}>
              Drag and drop your file here or <Browser>browse</Browser>
            </Instructions>
          </>
        )}
      </Box>
    </>
  );
};

export default Dropzone;
